import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { trackEvent } from "../helpers/trackEvent";
import { PageViewEvent } from "../types/Events";

const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackEvent(new PageViewEvent(location.pathname));
  }, [location.pathname]);

  return null;
};

export default PageViewTracker;
