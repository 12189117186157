import { useQuery } from "react-query";

import { Dossier } from "../../types/Dossier";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getDossier = (dossierId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["dossier", dossierId],
    queryFn: (): Promise<Dossier> => {
      return fetchWithAuth<Dossier>("getDossier", dossierId);
    },
    enabled: isQueryEnabled(options),
  });
};
