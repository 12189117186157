import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { ReactNode, useState } from "react";

export type APTabDetails = {
  label: string;
  children: ReactNode;
};

const APTabs = ({ tabs }: { tabs: APTabDetails[] }) => {
  const [value, setValue] = useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const renderTab = (label: string, key: number) => {
    const selected = value === key;
    return (
      <Grid key={key} item>
        <Button
          size="large"
          color="primary"
          variant={selected ? "outlined" : "contained"}
          disableElevation={true}
          sx={{ color: selected ? "primary.main" : "onPrimary.main" }}
          onClick={() => handleChange(key)}
        >
          {label}
        </Button>
      </Grid>
    );
  };

  const renderTabPanel = (children: ReactNode, key: number) => {
    const selected = value === key;

    return <Box key={key}>{selected && children}</Box>;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Grid container spacing={2}>
          {tabs.map((tab, i) => renderTab(tab.label, i))}
        </Grid>
      </Box>
      <Box sx={{ my: 2 }}>
        {tabs.map((tab, i) => renderTabPanel(tab.children, i))}
      </Box>
    </Box>
  );
};

export default APTabs;
