import { useMutation } from "react-query";

import { APFaqVoteType } from "../../types/Faq";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type CreateFaqVotePayload = {
  vote: APFaqVoteType | null;
  appLocationId: string | null;
  faqAppLocationId: string | null;
};

export const createFaqVote = (faqId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
    optimisiticUpdateOnMutate,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: CreateFaqVotePayload): Promise<void> => {
      return fetchWithAuth<void>("createFaqVote", faqId, {
        requestOptions: { method: "post", body: JSON.stringify(payload) },
      });
    },
    onMutate: async (payload) => {
      const queryKey = ["faqVote", faqId, payload.appLocationId];

      optimisiticUpdateOnMutate(queryKey, {
        id: "placeholder",
        faqId: faqId,
        vote: payload.vote,
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: (data, error, payload) =>
      defaultOnMutateSettled([["faqVote", faqId, payload.appLocationId]]),
  });
};
