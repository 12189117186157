import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../api/AuthProvider";
import { addModalToHref } from "../helpers/routes";
import { CRMAppLocationIdentifier, FaqAppLocation } from "../types/Faq";

interface FaqLinkProps {
  children: ReactNode;
  appLocations: FaqAppLocation[];
  appLocationIdentifier: CRMAppLocationIdentifier;
  hideIfNoFaqs?: boolean;
}

const FaqLink = ({
  children,
  appLocations,
  appLocationIdentifier,
  hideIfNoFaqs,
}: FaqLinkProps) => {
  const { getScopedPageUrl } = useAuth();
  const appLocation = appLocations.find(
    (location) => location.appLocationIdentifier === appLocationIdentifier
  );
  const isClickable = appLocation && appLocation.faqCount > 0;
  const linkTo = appLocation
    ? addModalToHref(
        getScopedPageUrl("modalAppLocation", appLocation.id),
        window.location.href
      )
    : "";

  return (
    <>
      <></>
      {isClickable ? (
        <Link to={linkTo} replace={true}>
          {children}
        </Link>
      ) : hideIfNoFaqs ? (
        <></>
      ) : (
        children
      )}
    </>
  );
};

export default FaqLink;
