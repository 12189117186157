import { useEffect, useState } from "react";
import { Location, useLocation } from "react-router-dom";

import { isModalRoute } from "../helpers/routes";

const ScrollToTop = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(
    null as Location<unknown> | null
  );

  useEffect(() => {
    if (
      !isModalRoute(location) &&
      (!prevLocation || !isModalRoute(prevLocation))
    ) {
      // If the current route or previous route is a modal then we don't scroll to the top else we scroll
      window.scrollTo(0, 0);
    }
    setPrevLocation(location);
  }, [location]);

  return null;
};

export default ScrollToTop;
