import { Contact } from "./Contact";
import { State } from "./State";

export const METRO_FINANCIER = "Metro";
export const ASSET_AND_LIABILITY_ID_PROPERTY = 100000000;

export type DossierStatus =
  | "Active"
  | "New"
  | "Closed"
  | "Closing"
  | "Cancelled";
export type OpportunityStatus = "open" | "won" | "lost";
export type DossierType =
  | "new"
  | "renovation"
  | "refinance"
  | "sale-and-leaseback";
export type DossierEventVisibility =
  | "visible"
  | "not-visible"
  | "after-completion"
  | "current-past-phases";
export type NextDriverAction =
  | "request-employer-setup"
  | "finance-application"
  | "direct-debit"
  | "packaging-agreement"
  | "no-action-required";
export type DossierEventTriggeredBy = "driver" | "employer" | "auto-ux";
export type EmployerUsesAutoUXOption = "yes" | "no" | "unsure";
export type FinanceStatus =
  | "new"
  | "submit-attempted"
  | "entered"
  | "approval-requested"
  | "active"
  | "rejected";
export type DriversLicenseType =
  | "full"
  | "provisional"
  | "learner"
  | "heavy-vehicle"
  | "none";
export type AustralianResidencyStatus =
  | "citizen"
  | "nz-citizen"
  | "permanent-resident"
  | "working-visa"
  | "other";
export type MaritalStatus = "single" | "married" | "defacto";
export type AddressResidenceStatus =
  | "own-outright"
  | "own-mortgage"
  | "rent"
  | "boarding"
  | "live-with-relatives";
export type EmploymentType =
  | "permanent-ft"
  | "permanent-pt"
  | "casual"
  | "contractor"
  | "partner"
  | "self-employed"
  | "director"
  | "unemployed"
  | "other";
export type PayFrequency = "weekly" | "fortnightly" | "monthly" | "bi-monthly";

export type Opportunity = {
  id: string;
  packagingAgreementRequested: boolean;
  driverRequestedEmployerSetup: boolean;
  financeApprovalEntered: boolean;
  employerExecutedPackagingAgreement: boolean;
  employerRejecetedPackaging: boolean;
  driverExecutedPackagingAgreement: boolean;
  newAccountName: string;
  parentAccountId: string;
  parentAccountName: string;
  primaryQuoteId: string | null;
  status: OpportunityStatus;
  canCreateQuote: boolean;
  catchEContactUrl: string | null;
  referralCode: string | null;
  referrerId: string | null;
};

export type Dossier = {
  id: string;
  crmContactId: string;
  crmContactName: string;
  identity: string;
  name: string;
  status: DossierStatus;
  startDate: string;
  endDate: string;
  vehicleMakeName: string;
  vehicleModelName: string;
  vehicleModelyear: string;
  vehicleVariantName: string;
  vehicleVariantImage: string;
  noteToDriver: string | null;
  quoteCount?: number;
  opportunity?: Opportunity;
  contact?: Contact;
};

export type DossierPhaseEvent = {
  id: string;
  eventId: number;
  eventName: string;
  eventTriggeredBy: DossierEventTriggeredBy;
  visibility: DossierEventVisibility;
  completed: boolean;
  completedBy: string | null;
  completedOn: string | null;
  completionText: string;
};

export type DossierPhase = {
  id: number;
  name: string;
  events: DossierPhaseEvent[];
  completed: boolean;
};

export type DetailedDossierStatus = {
  dossierId: string;
  dossierType: DossierType;
  dossierPhases: DossierPhase[];
  nextDriverAction: NextDriverAction;
  currentPhaseId: number | null;
};

export type FinancialApplicationPersonalInfo = {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  mobile: string | null;
  phone: string | null;
  numberOfDependents: number | null;
  maritalStatus: MaritalStatus | null;
};

export type FinanceApplicationIdentity = {
  driversLicense: {
    type: DriversLicenseType | null;
    number: string | null;
    cardNumber: string | null;
    frontImageUrl: string | null;
    frontImageName: string | null;
    backImageUrl: string | null;
    backImageName: string | null;
    state: State | null;
  };
  residency: {
    status: AustralianResidencyStatus | null;
    passportImageUrl: string | null;
    passportImageName: string | null;
    visaImageUrl: string | null;
    visaImageName: string | null;
    visaType: string | null;
    visaExpiry: string | null;
  };
};

export type FinancialApplicationAddress = {
  current: {
    streetAddress: string | null;
    suburb: string | null;
    state: State | null;
    postcode: string | null;
    residenceStatus: AddressResidenceStatus | null;
    yearsAt: number | null;
    monthsAt: number | null;
    landlord: string | null;
    landlordPhone: string | null;
  };
  previous: {
    streetAddress: string | null;
    suburb: string | null;
    state: State | null;
    postcode: string | null;
    yearsAt: number | null;
    monthsAt: number | null;
  };
};

export type FinancialApplicationEmployment = {
  latestPayslipUrl: string | null;
  latestPayslipName: string | null;
  prevPayslipUrl: string | null;
  prevPayslipName: string | null;
  annualSalary: {
    total: number | null;
    perPay: number | null;
    payFrequency: PayFrequency | null;
    netPayPerMonth: number | null;
  };
  current: {
    type: EmploymentType | null;
    jobTitle: string | null;
    employerName: string | null;
    employerContactName: string | null;
    employerContactPhone: string | null;
    lastTaxReturnUrl: string | null;
    lastTaxReturnName: string | null;
    prevTaxReturnUrl: string | null;
    prevTaxReturnName: string | null;
    yearsAt: number | null;
    monthsAt: number | null;
  };
  previous: {
    jobTitle: string | null;
    employerName: string | null;
    employerContactName: string | null;
    employerContactPhone: string | null;
    yearsAt: number | null;
    monthsAt: number | null;
  };
};

export type FinancialApplicationIncome = {
  investmentPropertyIncome: number | null;
  investmentIncome: number | null;
  govtBenefitIncome: number | null;
  otherIncome: number | null;
  rent: {
    amount: number | null;
    frequency: PayFrequency | null;
    monthlyAmount: number | null;
  };
  mortgage: {
    amount: number | null;
    frequency: PayFrequency | null;
    monthlyAmount: number | null;
  };
  vehicleCosts: {
    amount: number | null;
    frequency: PayFrequency | null;
    monthlyAmount: number | null;
  };
  educationCosts: {
    amount: number | null;
    frequency: PayFrequency | null;
    monthlyAmount: number | null;
  };
  generalExpenses: {
    amount: number | null;
    frequency: PayFrequency | null;
    monthlyAmount: number | null;
  };
  otherExpenses: {
    amount: number | null;
    frequency: PayFrequency | null;
    monthlyAmount: number | null;
  };
  spouse: {
    include: boolean;
    annualSalary: number | null;
    salaryPerPay: number | null;
    netPayPerMonth: number | null;
    payFrequency: PayFrequency | null;
    employmentType: EmploymentType | null;
    lastTaxReturnUrl: string | null;
    lastTaxReturnName: string | null;
    prevTaxReturnUrl: string | null;
    prevTaxReturnName: string | null;
    latestPayslipUrl: string | null;
    latestPayslipName: string | null;
    incomeDeclarationUrl: string | null;
    incomeDeclarationName: string | null;
  };
  totalMonthlyIncome: number | null;
  totalMonthlyExpenses: number | null;
  netMonthlyIncome: number | null;
};

export type FinancialApplicationCreditCards = {
  creditCardProviders: string | null;
  totalCreditCardLimit: number | null;
  totalCreditCardBalance: number | null;
};

export type FinancialApplicationAssetsAndLiabilitiesTotals = {
  totalAssets: number | null;
  totalLiabilities: number | null;
  totalNetWorth: number | null;
};

export type AssetAndLiabilityType = {
  id: string;
  name: string;
  typeId: number;
  typeLabel: string;
  isAsset: boolean;
  isLiability: boolean;
  isActive: boolean;
};

export type AssetAndLiability = {
  id: string;
  financeId: string;
  name: string;
  description: string | null;
  assetValue: number | null;
  liabilityValue: number | null;
  typeId: number | null;
  typeLabel: string | null;
  displayOrder: number | null;
  financier: string | null;
  incomePerMth: number | null;
  expensePerMth: number | null;
};

export type FinanceApplication = {
  id: string;
  isLocked: boolean;
  isHidden: boolean;
  status: FinanceStatus;
  financier: string;
  personal: FinancialApplicationPersonalInfo;
  identity: FinanceApplicationIdentity;
  address: FinancialApplicationAddress;
  employment: FinancialApplicationEmployment;
  income: FinancialApplicationIncome;
  assetsAndLiabilities: AssetAndLiability[];
  assetsAndLiabilitiesTotals: FinancialApplicationAssetsAndLiabilitiesTotals;
  creditCards: FinancialApplicationCreditCards;
};

export const DossierNextActionRouteMap: Record<NextDriverAction, string> = {
  "request-employer-setup": "/employer-setup",
  "finance-application": "/finance-application",
  "packaging-agreement": "/packaging-agreement",
  "direct-debit": "/direct-debit",
  "no-action-required": "/action-confirmation/not-ready",
};

export const buildNextActionRoute = (
  dossierId: string,
  nextAction: NextDriverAction
) => {
  const nextActionPath = DossierNextActionRouteMap[nextAction];

  return `/dossiers/${dossierId}${nextActionPath}`;
};
