import { Navigate } from "react-router-dom";

import { useAuth } from "../api/AuthProvider";
import { User } from "../types/User";

const InitialSetupRoute = ({
  me,
  children,
}: {
  me: User;
  children: JSX.Element;
}) => {
  const { getScopedPageUrl } = useAuth();
  if (me && me.initialSetupCompleted) {
    return <Navigate to={getScopedPageUrl("home")} />;
  }

  return children;
};

export default InitialSetupRoute;
