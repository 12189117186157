import { useQuery,  } from 'react-query';

import { PayrollCycle } from '../../types/User';
import { useApi } from '../ApiProvider';

export const getMyEmployerPayrollCycles = () => {  
  const { fetchWithAuth } = useApi();
  return useQuery(["employer-payroll-cycles"], (): Promise<PayrollCycle[]> => {
    return fetchWithAuth<PayrollCycle[]>("getMyEmployerPayrollCycles");
  });
};