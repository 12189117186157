import { useQuery,  } from 'react-query';

import { Dossier } from '../../types/Dossier';
import { useApi } from '../ApiProvider';
import { APQueryOptions, useAPQuery } from '../APQueryProvider';

export const getMyDossiers = (options: APQueryOptions = {}) => {  
  const { fetchWithAuth} = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["dossiers"],
    queryFn: (): Promise<Dossier[]> => {
      return fetchWithAuth<Dossier[]>("getMyDossiers");
    },
    enabled: isQueryEnabled(options)
  });
};
