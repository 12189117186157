import { useQuery,  } from 'react-query';

import { FinanceApplication } from '../../types/Dossier';
import { useApi } from '../ApiProvider';
import { APQueryOptions, useAPQuery } from '../APQueryProvider';

export const getFinanceApplication = (dossierId: string, options: APQueryOptions = {}) => {  
  const { fetchWithAuth} = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["finance-application", dossierId],
    queryFn: (): Promise<FinanceApplication> => {
      return fetchWithAuth<FinanceApplication>("getFinanceApplication", dossierId);
    },
    enabled: isQueryEnabled(options)
  });
};

