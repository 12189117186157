import "survey-core/defaultV2.min.css";
import "../surveyjs.css";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import { getSurvey } from "../../api/survey/getSurvey";
import { hasSurveyAwaitingResponse } from "../../api/survey/hasSurveyAwaitingResponse";
import {
  updateSurveyResponse,
  UpdateSurveyResponsePayload,
} from "../../api/survey/updateSurveyResponse";
import PageContainer from "../../components/PageContainer";
import APSurvey from "../components/APSurvey";

const ViewSurvey = () => {
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const { getScopedPageUrl } = useAuth();

  if (!surveyId) {
    // TODO: improve client side error messaging
    throw new Error("survey id param is misisng");
  }
  const { mutateAsync: mutateAsyncUpdate } = updateSurveyResponse(
    surveyId || ""
  );

  const navigateToHome = () => {
    navigate(getScopedPageUrl("home"));
  };

  const onSurveyComplete = (responsePayload: UpdateSurveyResponsePayload) => {
    mutateAsyncUpdate(responsePayload);
  };

  const survey = getSurvey(surveyId);
  const surveyIsAwaitingResponse = hasSurveyAwaitingResponse(surveyId);

  if (survey.isError) {
    throw survey.error;
  }

  if (surveyIsAwaitingResponse.isError) {
    throw surveyIsAwaitingResponse.error;
  }

  const isLoading = survey.isFetching || surveyIsAwaitingResponse.isFetching;
  const isSuccess = survey.isSuccess && surveyIsAwaitingResponse.isSuccess;

  const surveyComponent = useMemo(() => {
    return (
      <>
        {isSuccess && (
          <Box>
            {surveyIsAwaitingResponse.data && (
              <APSurvey
                surveyId={survey.data.id}
                schema={JSON.parse(survey.data.schema)}
                navigateToHome={navigateToHome}
                onComplete={onSurveyComplete}
              />
            )}
            {!surveyIsAwaitingResponse.data && (
              <Box>
                <Typography variant="headlineLarge">Feedback Done</Typography>
                <Typography variant="labelLarge">
                  Looks like you don’t have any feedback surveys to complete
                  right now.
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    disableElevation={true}
                    onClick={navigateToHome}
                  >
                    Home
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  }, [isLoading]);
  return <PageContainer loading={isLoading}>{surveyComponent}</PageContainer>;
};

export default ViewSurvey;
