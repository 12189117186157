import { VehiclePurchaseType } from "../types/Quote";

const puchaseTypeLabelMap: Record<VehiclePurchaseType, string> = {
  new: "New",
  used: "Used",
  "sale-and-leaseback": "Lease my own car",
  refinance: "Refinance",
  renovation: "Renovation",
  "external-financier-takeover": "External Financier Takeover",
};

export const getPurchaseTypeLabel = (
  purchaseType: VehiclePurchaseType
): string => {
  return puchaseTypeLabelMap[purchaseType];
};
