import { useQuery } from "react-query";

import { VehicleMake } from "../../types/Vehicles";
import { useApi } from "../ApiProvider";
import {
  APQueryOptions,
  LONG_STALE_TIME,
  useAPQuery,
} from "../APQueryProvider";

export const getVehicleMakes = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["vehicle-makes"],
    queryFn: (): Promise<VehicleMake[]> => {
      return fetchWithAuth<VehicleMake[]>("getVehicleMakes");
    },
    enabled: isQueryEnabled(options),
    staleTime: LONG_STALE_TIME,
  });
};
