import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";

import APDateField from "../../../components/form/APDateField";
import APRadioGroup from "../../../components/form/APRadioGroup";
import APTextField from "../../../components/form/APTextField";
import { MaritalStatus } from "../../../types/Dossier";
import { FinanceApplicationInputs } from "..";

export const maritalStatusOptions: {
  id: MaritalStatus;
  label: string;
}[] = [
  { id: "single", label: "Single" },
  { id: "married", label: "Married" },
  { id: "defacto", label: "Defacto" },
];

const FinanceApplicationInitialForm = ({
  control,
  errors,
  setError,
  clearErrors,
  readOnly,
  highlightRequired,
}: {
  control: Control<FinanceApplicationInputs>;
  errors: FieldErrors<FinanceApplicationInputs>;
  setError: UseFormSetError<FinanceApplicationInputs>;
  clearErrors: UseFormClearErrors<FinanceApplicationInputs>;
  readOnly: boolean;
  highlightRequired: boolean;
}) => {
  const eighteenYearsAgo = dayjs().subtract(18, "year");
  const yearNineteenHundred = dayjs().year(1900);

  return (
    <Stack spacing={2}>
      <APTextField<FinanceApplicationInputs>
        name="firstName"
        label="First Name"
        control={control}
        validations={{
          maxLength: 100,
        }}
        errors={errors}
        defaultValue=""
        showRequired={true}
        readOnly={readOnly}
        highlightOnEmpty={highlightRequired}
      />
      <APTextField<FinanceApplicationInputs>
        name="middleName"
        label="Middle Name"
        control={control}
        validations={{
          maxLength: 100,
        }}
        errors={errors}
        defaultValue=""
        helperText="Optional"
        readOnly={readOnly}
      />
      <APTextField<FinanceApplicationInputs>
        name="lastName"
        label="Last Name"
        control={control}
        validations={{
          maxLength: 100,
        }}
        errors={errors}
        defaultValue=""
        showRequired={true}
        readOnly={readOnly}
        highlightOnEmpty={highlightRequired}
      />
      <APDateField
        name="dateOfBirth"
        label="Date of Birth"
        control={control}
        defaultValue={null}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
        minDate={yearNineteenHundred}
        maxDate={eighteenYearsAgo}
        maxDateErrorMessage="You must be older than 18yr to apply for a novated lease."
        showRequired={true}
        readOnly={readOnly}
        highlightOnEmpty={highlightRequired}
      />

      <APTextField<FinanceApplicationInputs>
        name="mobile"
        label="Mobile"
        control={control}
        validations={{
          minLength: 10,
          maxLength: 12,
          formatValidation: /[0-9\s()+-]+/,
        }}
        errors={errors}
        defaultValue=""
        showRequired={true}
        readOnly={readOnly}
        highlightOnEmpty={highlightRequired}
      />
      <APTextField<FinanceApplicationInputs>
        name="phone"
        label="Phone"
        control={control}
        validations={{
          minLength: 8,
          maxLength: 20,
          formatValidation: /[0-9\s()+-]+/,
        }}
        errors={errors}
        defaultValue=""
        helperText="Optional"
        readOnly={readOnly}
      />
      <APTextField<FinanceApplicationInputs>
        name="numberOfDependents"
        label="No. of Dependants"
        control={control}
        validations={{
          maxLength: 2,
          formatValidation: /[0-9\s()+-]+/,
        }}
        errors={errors}
        defaultValue=""
        showRequired={true}
        readOnly={readOnly}
        highlightOnEmpty={highlightRequired}
      />

      <APRadioGroup
        name="maritalStatus"
        label="Marital Status"
        validations={{}}
        errors={errors}
        control={control}
        defaultValue={null}
        options={maritalStatusOptions}
        readOnly={readOnly}
        highlightOnEmpty={highlightRequired}
      />
    </Stack>
  );
};

export default FinanceApplicationInitialForm;
