import AutorenewIcon from "@mui/icons-material/Autorenew";
import { SxProps, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";

const RefreshButton = ({
  onClick,
  sx,
}: {
  onClick: () => void;
  sx?: SxProps<Theme>;
}) => {
  return (
    <IconButton
      size="large"
      aria-label="refresh"
      color="onSurface"
      onClick={() => onClick()}
      sx={{ ...sx }}
    >
      <AutorenewIcon />
    </IconButton>
  );
};

export default RefreshButton;
