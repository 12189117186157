import "react-responsive-carousel/lib/styles/carousel.min.css";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { ReactNode, useState } from "react";
import { Control } from "react-hook-form";

import APAccordion from "../../components/APAccordion";
import APDetailRow from "../../components/APDetailRow";
import APTabs from "../../components/APTabs";
import CatchEButton from "../../components/buttons/CatchEButton";
import ImageCarousel from "../../components/ImageCarousel";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import RestrictedAppScope from "../../components/RestrictedAppScope";
import TermsButton from "../../components/TermsButton";
import { toDollars } from "../../helpers/currencies";
import { appendRedbookImageParams } from "../../helpers/files";
import {
  calculateWeeklyVehicleOptionCost,
  VehicleOptions,
} from "../../types/Vehicles";
import VehicleOptionCard from "./VehicleOptionCard";
import VehicleOptionDetails from "./VehicleOptionDetails";

interface QuotePersonaliserProps {
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleVariantDescription: string;
  vehicleImages: string[];
  basePrice: number;
  series: string;
  body: string;
  seats: number;
  transmission: string;
  drive: string;
  fuelType: string;
  releaseDate: string;
  co2Emissions: number;
  ancapRating: number;
  vehicleOptions: VehicleOptions;
  standardFeatures: string[];
  savingsPercentage: number;
  term: number;
  catchEQuoteUrl: string;
  handleEdit: () => void;
  handleOptionDownload: (url: string, filename: string) => void;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  isSubmitting: boolean;
  control: Control;
}

const QuotePersonaliser = ({
  vehicleMake,
  vehicleModel,
  vehicleYear,
  vehicleVariantDescription,
  vehicleImages,
  basePrice,
  series,
  body,
  seats,
  transmission,
  drive,
  fuelType,
  co2Emissions,
  ancapRating,
  vehicleOptions,
  standardFeatures,
  savingsPercentage,
  term,
  catchEQuoteUrl,
  handleEdit,
  handleOptionDownload,
  handleSubmit,
  isSubmitting,
  control,
}: QuotePersonaliserProps) => {
  const [highlightedOptionId, setHighlightedOptionId] = useState(
    null as string | null
  );

  const onHighlightClick = (optionId: string) => {
    setHighlightedOptionId(optionId);
  };

  const onCloseModal = () => {
    setHighlightedOptionId(null);
  };

  // Build Rendered option card map
  const dealerOptionCards: Record<string, ReactNode> = {};
  const manufacturerOptionCards: ReactNode[] = [];

  Object.keys(vehicleOptions.options.dealer).forEach((optionId) => {
    const option = vehicleOptions.options.dealer[optionId];

    const renderedOption = (
      <VehicleOptionCard
        id={option.id}
        catchEOptionId={option.catchEOptionId}
        label={option.name}
        price={`${toDollars(
          calculateWeeklyVehicleOptionCost(
            option.price,
            savingsPercentage,
            term
          ),
          0
        )}/wk^`}
        imageUrl={option.imageUrl}
        control={control}
        handleHighlightClick={onHighlightClick}
        hasHighlight={option.type === "auto-ux"}
        showImage={option.type === "auto-ux"}
      />
    );

    dealerOptionCards[optionId] = renderedOption;
  });

  // Build category tabs
  const tabs = vehicleOptions.categories.map((category) => {
    return {
      label: category.name,
      children: (
        <Stack spacing={1}>
          {Object.keys(dealerOptionCards).map((optionId) => {
            const isHidden = category.optionIds.indexOf(optionId) === -1;

            return (
              <Box
                sx={{ display: isHidden ? "none" : "initial" }}
                key={optionId}
              >
                {dealerOptionCards[optionId]}
              </Box>
            );
          })}
        </Stack>
      ),
    };
  });

  const highlightedOption =
    !!highlightedOptionId && vehicleOptions.options.dealer[highlightedOptionId];

  // Build manufacturerOptions
  const orderedManufacturerOptions = Object.values(
    vehicleOptions.options.manufacturer
  );
  orderedManufacturerOptions.sort((a, b) =>
    a.description.localeCompare(b.description)
  );

  orderedManufacturerOptions.forEach((option) => {
    const renderedOption = (
      <VehicleOptionCard
        id={option.id}
        key={option.id}
        catchEOptionId={option.catchEOptionId}
        label={option.name}
        price={`${toDollars(
          calculateWeeklyVehicleOptionCost(
            option.price,
            savingsPercentage,
            term
          ),
          0
        )}/wk^`}
        imageUrl={null}
        control={control}
        showImage={false}
      />
    );

    manufacturerOptionCards.push(renderedOption);
  });

  return (
    <Box>
      <Modal
        open={!!highlightedOptionId && !!highlightedOption}
        onClose={onCloseModal}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Paper
            sx={{
              p: 2,
              overflowY: "auto",
              maxHeight: "95vh",
              maxWidth: "95vw",
            }}
          >
            {highlightedOption && (
              <VehicleOptionDetails
                option={highlightedOption}
                savingsPercentage={savingsPercentage}
                term={term}
                handleOptionDownload={handleOptionDownload}
              />
            )}
            <Box sx={{ mt: 3 }}>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                disableElevation={true}
                onClick={onCloseModal}
              >
                Close
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
      <Stack component="form" spacing={2} onSubmit={handleSubmit}>
        <Box>
          <APAccordion
            title={`${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleVariantDescription}`}
            hideExpand={vehicleImages.length === 0}
          >
            {vehicleImages.length > 0 && (
              <Box pt={2}>
                <ImageCarousel
                  images={vehicleImages.map((image) =>
                    appendRedbookImageParams(image)
                  )}
                />
              </Box>
            )}
          </APAccordion>
        </Box>

        <Box>
          <APAccordion
            title={"Vehicle Details"}
            leftIcon={<EditOutlinedIcon />}
            handleLeftIconClick={handleEdit}
          >
            <APDetailRow label="Base Price" value={toDollars(basePrice, 0)} />
            <APDetailRow label="Series" value={series} />
            <APDetailRow label="Body" value={body} />
            <APDetailRow label="Seat Capacity" value={`${seats}`} />
            <APDetailRow label="Transmission" value={transmission} />
            <APDetailRow label="Drive" value={drive} />
            <APDetailRow label="Fuel Type" value={fuelType} />
            <APDetailRow label="Release Date" value={vehicleYear} />
            <APDetailRow label="CO2 Emissions" value={`${co2Emissions}`} />
            <APDetailRow label="ANCAP Rating" value={`${ancapRating} star`} />
          </APAccordion>
        </Box>

        <Box>
          <APAccordion title="Vehicle Options" defaultExpanded>
            <Box sx={{ my: 2 }}>
              <APTabs tabs={tabs} />
            </Box>
          </APAccordion>
        </Box>

        <Box>
          <APAccordion title="Manufacturer Options" defaultExpanded>
            <Stack spacing={1} sx={{ mt: 2 }}>
              {manufacturerOptionCards}
            </Stack>
          </APAccordion>
          <TermsButton termsId="vehicleOption" />
        </Box>

        <Box>
          <APAccordion title={"Standard Features"}>
            <Box>
              {standardFeatures.map((feature, i) => {
                return (
                  <Typography key={i} variant="titleMedium">
                    {feature}
                  </Typography>
                );
              })}
            </Box>
          </APAccordion>
        </Box>

        <Box
          sx={{
            pt: 2,
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Box sx={{ mr: 2 }}>
            <RestrictedAppScope scope="internal">
              <CatchEButton url={catchEQuoteUrl} />
            </RestrictedAppScope>
          </Box>
          <Box>
            <Button
              size="large"
              color="primary"
              variant="contained"
              disableElevation={true}
              startIcon={<EditOutlinedIcon />}
              sx={{ mr: 2 }}
              onClick={handleEdit}
            >
              Edit
            </Button>
          </Box>
          <Box>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              type="submit"
              disableElevation={true}
              startIcon={<PlayArrowOutlinedIcon />}
            >
              View Quote
            </Button>
          </Box>
        </Box>
        {isSubmitting && <LoadingBackdrop />}
      </Stack>
    </Box>
  );
};

export default QuotePersonaliser;
