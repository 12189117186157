import { useQuery } from "react-query";

import { APDocument, APDocumentType } from "../../types/Document";
import { PaginationResponse } from "../../types/Pagination";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type DocumentFilters = {
  pageSize: number;
  nextPageLink?: string;
  accountIds?: string[];
  documentTypes?: APDocumentType[];
  dateFrom?: string;
  dateTo?: string;
  date2From?: string;
  date2To?: string;
  nameSearch?: string;
};

export const getDocuments = (
  filters: DocumentFilters,
  options: APQueryOptions = {}
) => {
  const { nextPageLink, pageSize, accountIds, documentTypes, ...otherFilters } =
    filters;
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["getDocuments", filters],
    queryFn: (): Promise<PaginationResponse<APDocument>> => {
      return fetchWithAuth<PaginationResponse<APDocument>>(
        "getDocuments",
        undefined,
        {
          queryParams: {
            ...otherFilters,
            nextPage: nextPageLink || undefined,
            pageSize: pageSize ? `${pageSize}` : undefined,
            accountIds: accountIds ? accountIds.join(",") : undefined,
            documentTypes: documentTypes ? documentTypes.join(",") : undefined,
          },
        }
      );
    },
    enabled: isQueryEnabled(options),
    keepPreviousData: options.keepPreviousData,
  });
};
