export const toDollars = (amount: number, decimalPlaces: number): string => {
  return Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    maximumFractionDigits: decimalPlaces,
  }).format(amount);
};

export const toFormattedNumber = (
  amount: number,
  decimalPlaces: number
): string => {
  return Intl.NumberFormat("en-AU", {
    maximumFractionDigits: decimalPlaces,
  }).format(amount);
};
