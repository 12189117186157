import { useQuery } from "react-query";

import { VehicleModelYear } from "../../types/Vehicles";
import { useApi } from "../ApiProvider";
import {
  APQueryOptions,
  LONG_STALE_TIME,
  useAPQuery,
} from "../APQueryProvider";

export const getVehicleModelYears = (
  modelId: string | null,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["vehicle-model-years", modelId],
    queryFn: (): Promise<VehicleModelYear[]> => {
      if (modelId) {
        return fetchWithAuth<VehicleModelYear[]>(
          "getVehicleModelYears",
          undefined,
          {
            queryParams: { modelId },
          }
        );
      } else {
        return Promise.resolve([]);
      }
    },
    enabled: isQueryEnabled(options),
    staleTime: LONG_STALE_TIME,
  });
};
