import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { getFaqs } from "../../api/faq/getFaqs";
import ModalContainer from "../../components/ModalContainer";
import { Faq } from "../../types/Faq";
import FaqCarousel from "../components/FaqCarousel";
import FaqDetails from "../components/FaqDetails";
import FaqListCard from "../components/FaqListCard";

const ViewAppLocationFaqs = () => {
  const { faqId, appLocationId } = useParams();
  const navigate = useNavigate();
  const { fetchWithAuth } = useApi();

  if (!appLocationId) {
    // TODO: improve client side error messaging
    throw new Error("app location id param is misisng");
  }

  const faqs = getFaqs(appLocationId);

  if (faqs && faqs.isError) {
    throw faqs.error;
  }

  const trackView = (faqId: string) => {
    const faq = faqs.isSuccess
      ? faqs.data.find((faq) => faq.id === faqId)
      : undefined;

    fetchWithAuth<void>("createFaqView", faqId, {
      requestOptions: {
        method: "post",
        body: JSON.stringify({
          appLocationId: appLocationId,
          faqAppLocationId: faq?.faqApplocationId || null,
        }),
      },
    });
  };

  useEffect(() => {
    if (faqs.isSuccess) {
      // Remove modal query param if there is no FAQs to display
      if (faqs.data.length === 0) {
        navigate(window.location.pathname, { replace: true });
      } else if (faqs.data.length === 1) {
        // Trigger FAQ view if only one faq
        faq = faqs.data[0];
        trackView(faq.id);
      }
    }
  }, [faqs.isSuccess]);

  let faq;
  if (faqs.isSuccess) {
    if (faqs.data.length === 1) {
      faq = faqs.data[0];
    } else if (faqId) {
      faq = faqs.data.find((faq) => faq.id === faqId);
    }
  }

  const renderFaq = (faq: Faq) => {
    return (
      <FaqListCard
        key={`list-${faq.id}`}
        faq={faq}
        appLocationId={appLocationId}
        handleFaqClick={trackView}
      />
    );
  };

  return (
    <ModalContainer title={faq ? undefined : "FAQ"} loading={faqs.isLoading}>
      {faqs.isSuccess && (
        <Box>
          {faq ? (
            <FaqDetails faq={faq} appLocationId={appLocationId} />
          ) : (
            <Box>
              <Typography variant="labelLarge">
                Click on one of the FAQ’s to learn more.
              </Typography>
              {faqs.data.length > 5 && (
                <Box>
                  <FaqCarousel
                    faqs={faqs.data}
                    appLocationId={appLocationId}
                    handleFaqClick={trackView}
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  rowGap: 1,
                  mt: 2,
                }}
              >
                {faqs.data.map(renderFaq)}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </ModalContainer>
  );
};

export default ViewAppLocationFaqs;
