import CheckIcon from "@mui/icons-material/Check";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";

export type ToggleButtonOption = {
  label: string;
  id: string | number;
};

interface APToggleGroupProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  options: ToggleButtonOption[];
  defaultValue: PathValue<T, Path<T>> | undefined;
}

const APToggleGroup = <T extends FieldValues>({
  name,
  control,
  options,
  defaultValue,
}: APToggleGroupProps<T>) => {
  const renderButtonOptions = (selectedOption: PathValue<T, Path<T>>) => {
    const buttonOptions = options.map((option) => {
      return (
        <ToggleButton
          value={option.id}
          key={option.id}
          sx={{ display: "flex" }}
        >
          {selectedOption === option.id && (
            <CheckIcon sx={{ fontSize: "1rem", mr: 1 }} />
          )}
          {option.label}
        </ToggleButton>
      );
    });

    return buttonOptions;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <ToggleButtonGroup
          color="primary"
          {...field}
          exclusive
          size="small"
          fullWidth={true}
        >
          {renderButtonOptions(field.value)}
        </ToggleButtonGroup>
      )}
    />
  );
};

export default APToggleGroup;
