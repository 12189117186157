import { FieldValues, Path, UseFormSetValue } from "react-hook-form";

export const trimStringInputs = <T extends FieldValues>(
  inputs: T,
  setValue: UseFormSetValue<T>
) => {
  Object.keys(inputs).forEach((key) => {
    const name = key as Path<T>;
    if (!!inputs[name] && typeof inputs[name] === "string") {
      setValue(name as Path<T>, inputs[name].trim());
    }
  });
};
