import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";
import {
  NEW_LEAD_DELAY,
  NEW_LEAD_DELAY_ALERT,
  NEW_LEAD_TIMEOUT,
  NEW_LEAD_TIMEOUT_ALERT,
} from "./qualifyLead";

export const qualifyNewLead = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
    alertOnLongRequest,
  } = useAPQuery();

  return useMutation({
    mutationFn: (): Promise<string> => {
      return alertOnLongRequest(
        fetchWithAuth<string>("qualifyNewLead", undefined, {
          requestOptions: { method: "post" },
        }),
        NEW_LEAD_DELAY,
        NEW_LEAD_DELAY_ALERT,
        NEW_LEAD_TIMEOUT,
        NEW_LEAD_TIMEOUT_ALERT
      );
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () => defaultOnMutateSettled([["dossiers"]]),
  });
};
