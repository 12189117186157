import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";

const CatchELoadingButton = ({
  isLoading,
  disabled,
  onClick,
}: {
  isLoading: boolean;
  disabled?: boolean;
  onClick: () => void;
}) => {
  return (
    <LoadingButton
      color="primary"
      size="large"
      startIcon={
        <Box
          component="img"
          src="/catchEIcon.png"
          sx={{ width: "24px", height: "24px" }}
        ></Box>
      }
      loading={isLoading}
      endIcon={<div></div>}
      loadingPosition="end"
      variant="outlined"
      sx={{ pr: isLoading ? 5 : undefined }}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading ? "Syncing" : "Sync"}
    </LoadingButton>
  );
};

export default CatchELoadingButton;
