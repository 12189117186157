import Box from "@mui/material/Box";

import APCarousel from "../../components/APCarousel";
import { Faq } from "../../types/Faq";
import FaqListCard from "./FaqListCard";

const FaqCarousel = ({
  faqs,
  appLocationId,
  handleFaqClick,
}: {
  faqs: Faq[];
  appLocationId: string;
  handleFaqClick: (faqId: string) => void;
}) => {
  const renderFaq = (faq: Faq) => {
    return (
      <Box key={`carousel-${faq.id}`} sx={{ height: "100%" }}>
        <FaqListCard
          faq={faq}
          appLocationId={appLocationId}
          handleFaqClick={handleFaqClick}
          highlightText
          flat
        />
      </Box>
    );
  };

  return <APCarousel>{faqs.map(renderFaq)}</APCarousel>;
};

export default FaqCarousel;
