import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { ReactNode, useState } from "react";

type StepStatus = "done" | "todo";
type CheckedStatus = "done" | "highlighted-todo" | "todo";

export type VerticalStepperCheckListItem = {
  status: CheckedStatus;
  text: string;
};

export type VerticalStepperStep = {
  status: StepStatus;
  text: string;
  checklistitems: VerticalStepperCheckListItem[];
};

interface APVerticalStepperProps {
  steps: VerticalStepperStep[];
  currentStep: number;
  finalIcon?: ReactNode;
  onItemClick?: () => void;
}

const APVerticalStepper = ({
  steps,
  finalIcon,
  onItemClick,
}: APVerticalStepperProps) => {
  const [expandedSteps, setExpandedSteps] = useState([] as number[]);

  const getStepColour = (step: VerticalStepperStep): string => {
    switch (step.status) {
      case "done":
        return "tertiaryGreen.main";
      case "todo":
        return "secondary.main";
    }
  };

  const getConnectorColour = (step: VerticalStepperStep): string => {
    switch (step.status) {
      case "done":
        return "tertiaryGreen.main";
      case "todo":
        return "surface.dark";
    }
  };

  const renderCheckListItem = (
    item: VerticalStepperCheckListItem,
    index: number
  ) => {
    return (
      <Box
        key={index}
        sx={{ display: "flex", ml: 1, alignItems: "center" }}
        onClick={() => onItemClick && onItemClick()}
      >
        <Box
          sx={{
            mr: 0.5,
            width: "1.5rem",
            display: "flex",
          }}
        >
          {item.status === "done" ? (
            <CheckIcon sx={{ color: "tertiaryGreen.main" }} />
          ) : (
            <Box
              sx={{
                width: "1.25rem",
                height: "1.25rem",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor:
                  item.status === "highlighted-todo"
                    ? "secondary.main"
                    : "grey.600",
              }}
            />
          )}
        </Box>
        <Typography variant="bodySmall">{item.text}</Typography>
      </Box>
    );
  };

  const handleChange = (index: number) => {
    const indexOf = expandedSteps.indexOf(index);

    if (indexOf !== -1) {
      expandedSteps.splice(indexOf, 1);
    } else {
      expandedSteps.push(index);
    }

    setExpandedSteps([...expandedSteps]);
  };

  return (
    <Stepper orientation="vertical" connector={<></>}>
      {steps.map((step, i) => {
        return (
          <Step key={i} active={true}>
            <StepLabel
              icon={
                <Box
                  sx={{
                    width: "1.5rem",
                    height: "1.5rem",
                    backgroundColor: getStepColour(step),
                    borderRadius: "100%",
                  }}
                ></Box>
              }
              sx={{
                padding: 0,
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", lineHeight: 1 }}
              >
                <Typography variant="titleLarge">{step.text}</Typography>
                {step.status === "done" && (
                  <Box>
                    <ExpandMoreIcon
                      sx={{
                        cursor: "pointer",
                        ml: 0.5,
                        transform:
                          expandedSteps.indexOf(i) !== -1
                            ? "rotate(180deg)"
                            : "",
                      }}
                      onClick={() => handleChange(i)}
                    />
                  </Box>
                )}
              </Box>
            </StepLabel>

            <StepContent
              sx={{
                borderColor: getConnectorColour(step),
                borderWidth: 4,
                ml: "10px",
              }}
            >
              <Box sx={{ minHeight: "1rem", my: "1rem" }}>
                <Collapse
                  in={step.status === "todo" || expandedSteps.indexOf(i) !== -1}
                >
                  <Stack spacing={1} sx={{ py: 0.5 }}>
                    {step.checklistitems.map(renderCheckListItem)}
                  </Stack>
                </Collapse>
              </Box>
            </StepContent>
          </Step>
        );
      })}
      {finalIcon && (
        <Step key="final" active={true}>
          <StepLabel
            icon={
              <Box
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                  color: "secondary.main",
                  marginLeft: "-6px",
                }}
              >
                {finalIcon}
              </Box>
            }
            sx={{
              padding: 0,
            }}
          />
        </Step>
      )}
    </Stepper>
  );
};

export default APVerticalStepper;
