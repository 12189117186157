export const DEFAULT_ACCOUNT = "8ec4ef3f-f27d-ee11-8179-002248933474";

export type Account = {
  id: string;
  accountAuthorised: boolean;
};

export type Contact = {
  id: string;
  crmUserId: string | null;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  email2: string | null;
  mobilePhone: string | null;
  activeBankAccountCount: number;
  parentAccountId: string;
  parentAccount?: Account;
  parentAccountEmployerName: string | null;
  employerPayrollCycleId: string | null;
  employerPayrollCycleName: string | null;
  employeeid: string | null;
  catchEDriverId: string | null;
};

export type BankAccount = {
  id: string;
  financialInstitution: string;
  accountName: string;
  bsb: string;
  accountNumber: string;
};
