import { useEffect, useState } from "react";
import Speedometer, {
  Arc,
  DangerPath,
  Needle,
  Progress,
} from "react-speedometer";

const APSpeedoLoader = ({
  startTime,
  duration,
  handleChange,
}: {
  startTime: number;
  duration: number;
  handleChange: () => void;
}) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    // Update the timer every millisecond
    const timerInterval = setInterval(() => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      setElapsedTime(elapsed);

      // Handle change after timer
      if (elapsed >= duration) {
        handleChange();
      }
    }, 1);

    // Clean up function to clear interval when component unmounts
    return () => clearInterval(timerInterval);
  }, [startTime]);

  return (
    <Speedometer
      width={40}
      value={elapsedTime}
      max={duration}
      angle={200}
      height={28}
      fontFamily="squada-one"
    >
      <Arc arcWidth={6} />
      <DangerPath offset={-3} color="#EB008B" arcWidth={6} />
      <Needle
        color="#1D1B20"
        baseOffset={0}
        circleRadius={0}
        baseWidth={2}
        offset={8}
      />
      <Progress arcWidth={6} color="#00ABD0" />
    </Speedometer>
  );
};

export default APSpeedoLoader;
