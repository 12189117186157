import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import StarFilledIcon from "@mui/icons-material/StarOutlined";
import StarOutlineIcon from "@mui/icons-material/StarOutlineOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import CatchEButton from "../../components/buttons/CatchEButton";
import VisibilityButton from "../../components/buttons/VisibilityButton";
import RestrictedAppScope from "../../components/RestrictedAppScope";
import { formatDate } from "../../helpers/dates";
import { getPurchaseTypeLabel } from "../../helpers/quotes";
import { VehiclePurchaseType } from "../../types/Quote";

const QuoteCard = ({
  id,
  name,
  isLocked,
  isPrimary,
  isVisible,
  vehicleMake,
  vehicleModel,
  vehicleYear,
  vehicleImage,
  vehicleOnroadCosts,
  purchaseType,
  annualKm,
  term,
  startDate,
  endDate,
  savings,
  weeklyNetCosts,
  catchEQuoteUrl,
  handleDownload,
  handleClone,
  handleEdit,
  handleNext,
  handleSetPrimary,
  handleSetVisibility,
  disablePrimaryToggle,
  hideClone,
}: {
  id: string;
  name: string;
  isLocked: boolean;
  isPrimary: boolean;
  isVisible: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleImage: string | null;
  vehicleOnroadCosts: string;
  purchaseType: VehiclePurchaseType;
  annualKm: string;
  term: number;
  startDate: string | null;
  endDate: string | null;
  savings: number;
  weeklyNetCosts: string;
  catchEQuoteUrl: string;
  handleDownload: (quoteId: string) => void;
  handleClone: (quoteId: string) => void;
  handleEdit: (quoteId: string) => void;
  handleNext: () => void;
  handleSetPrimary: (quoteId: string) => void;
  handleSetVisibility: (quoteId: string, visibility: boolean) => void;
  disablePrimaryToggle: boolean;
  hideClone: boolean;
}) => {
  const imageUrl = vehicleImage || "/photo-placeholder.png";
  const { getScopedPageUrl } = useAuth();

  return (
    <Card
      sx={{
        display: "flex",
        backgroundColor: "surface.main",
        flexDirection: "column",
      }}
      variant="outlined"
    >
      <CardActionArea
        sx={{
          p: {
            xs: 1,
            sm: 2,
          },
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "space-between",
        }}
        component={Link}
        to={getScopedPageUrl("viewQuote", id)}
      >
        <Box sx={{ display: "flex", alignSelf: "start", maxWidth: "100%" }}>
          <CardMedia
            component="img"
            sx={{
              minWidth: "5rem",
              flex: 2,
              mr: 1,
              ml: {
                xs: -1,
                sm: -2,
              },
              maxWidth: "20rem",
            }}
            image={imageUrl}
            alt={`${vehicleYear} ${vehicleMake} ${vehicleModel}`}
          />
          <Box
            sx={{
              display: "flex",
              flex: 3,
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box>
              <Typography variant="titleMedium" color="onSurface.main">
                {name}
              </Typography>
              <Typography variant="bodyMedium" color="onSurface.main">
                {vehicleYear} {vehicleMake} {vehicleModel}
              </Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="bodyLarge" color="onSurface.main" noWrap>
                {vehicleOnroadCosts} - {getPurchaseTypeLabel(purchaseType)}
              </Typography>
              <Stack spacing={1} direction="row">
                <Typography variant="bodyLarge" color="onSurface.main">
                  {annualKm}km
                </Typography>
                <Typography variant="bodyLarge" color="onSurface.main">
                  {term}mths
                </Typography>
              </Stack>
              {startDate && endDate && (
                <Typography variant="bodyMedium" color="onSurfaceVariant.main">
                  {formatDate(startDate)} - {formatDate(endDate)}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: 2,
            alignSelf: "stretch",
            justifyContent: "space-between",
            columnGap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ mr: 1, p: 0.5, backgroundColor: "secondary.main" }}>
              <Typography
                variant="headlineSmall"
                color="onPrimary.main"
                sx={{ whiteSpace: "nowrap" }}
              >
                {savings || savings === 0 ? savings.toFixed(0) : ""} %
              </Typography>
            </Box>
            <Typography variant="titleLarge" color="primary.main">
              Savings
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "right" }}
          >
            <Typography variant="titleSmall" color="onSurfaceVariant.main">
              Weekly Net Cost^
            </Typography>
            <Box sx={{ ml: 0.5, p: 1, backgroundColor: "primary.main" }}>
              <Typography variant="titleMedium" color="onPrimary.main">
                {weeklyNetCosts}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardActionArea>
      <Box
        sx={{
          display: "flex",
          flexDirecion: "row",
          justifyContent: "space-between",
          py: 1,
          px: {
            xs: 1,
            sm: 2,
          },
        }}
      >
        <Box sx={{ display: "flex" }}>
          {isPrimary ? (
            <StarFilledIcon sx={{ m: { sm: 1.5, xs: 1 } }} color="secondary" />
          ) : (
            <IconButton
              size="large"
              aria-label="favourite-quote"
              color="onSurface"
              onClick={() => handleSetPrimary(id)}
              disabled={disablePrimaryToggle}
              sx={{ p: { sm: 1.5, xs: 1 } }}
            >
              <StarOutlineIcon />
            </IconButton>
          )}

          <IconButton
            size="large"
            aria-label="download-quote"
            color="onSurface"
            onClick={() => handleDownload(id)}
            sx={{ p: { sm: 1.5, xs: 1 } }}
          >
            <DownloadIcon />
          </IconButton>
          {!hideClone && (
            <IconButton
              size="large"
              aria-label="clone-quote"
              color="onSurface"
              onClick={() => handleClone(id)}
              sx={{ p: { sm: 1.5, xs: 1 } }}
            >
              <FileCopyIcon />
            </IconButton>
          )}
          <RestrictedAppScope scope="internal">
            {!isPrimary && (
              <VisibilityButton
                isVisible={isVisible}
                onToggle={(isVisible: boolean) =>
                  handleSetVisibility(id, isVisible)
                }
                sx={{ p: { sm: 1.5, xs: 1 } }}
              />
            )}
          </RestrictedAppScope>
          <RestrictedAppScope scope="internal">
            <CatchEButton url={catchEQuoteUrl} sx={{ p: { sm: 1.5, xs: 1 } }} />
          </RestrictedAppScope>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!isLocked && (
            <Button
              size="large"
              color="primary"
              variant="outlined"
              onClick={() => handleEdit(id)}
            >
              Edit
            </Button>
          )}
          {isPrimary && (
            <RestrictedAppScope scope="external">
              <Button
                size="large"
                color="secondary"
                variant="contained"
                disableElevation={true}
                startIcon={<PlayArrowOutlined />}
                onClick={() => handleNext()}
                sx={{ ml: 1 }}
              >
                Next
              </Button>
            </RestrictedAppScope>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default QuoteCard;
