import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { DocumentFilters, getDocuments } from "../../api/document/getDocuments";
import BackButton from "../../components/buttons/BackButton";
import APAutoComplete, {
  AutoCompleteOption,
} from "../../components/form/APAutoComplete";
import APDateField from "../../components/form/APDateField";
import APForm from "../../components/form/APForm";
import APTextField from "../../components/form/APTextField";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import PageContainer from "../../components/PageContainer";
import PaginationContainer from "../../components/PaginationContainer";
import { buildAutoCompleteOptions } from "../../helpers/buildOptions";
import { downloadFile } from "../../helpers/download";
import { openInNewTab } from "../../helpers/routes";
import {
  EmployerDocumentPageName,
  filterAuthorisedRolesForPage,
  getDocumentTypesForEmployerPage,
  isAuthorisedToViewEmployerPage,
} from "../../helpers/user";
import { APDocument, APDocumentType } from "../../types/Document";
import { User } from "../../types/User";
import DocumentCard from "../components/DocumentCard";

type DocumentListConfig = {
  title: string;
  filters: (keyof DocumentFilterInputs)[];
  titleFields: (keyof APDocument)[];
  bodyFields: (keyof APDocument)[][];
  expandFields: (keyof APDocument)[][];
  filterLabels?: Partial<Record<keyof APDocument, string>>;
  fieldLabels?: Partial<Record<keyof APDocument, string>>;
};

const defaultConfig: Omit<DocumentListConfig, "title"> = {
  filters: ["accounts", "dateFrom", "dateTo", "name", "documentTypes"],
  titleFields: ["name", "documentType"],
  bodyFields: [["date"]],
  expandFields: [["accountName"], ["externalId"], ["note"]],
};

const documentListConfigMap: Record<
  EmployerDocumentPageName,
  DocumentListConfig
> = {
  employerInvoices: {
    title: "Invoice",
    filters: [
      "accounts",
      "dateFrom",
      "dateTo",
      "date2From",
      "date2To",
      "name",
      "documentTypes",
    ],
    titleFields: ["externalId"],
    bodyFields: [["date"], ["date2"]],
    expandFields: [["accountName"], ["name", "documentType"], ["note"]],
    filterLabels: {
      date: "Invoice Date",
      date2: "Due Date",
    },
    fieldLabels: {
      date: "Invoice",
      date2: "Due",
    },
  },
  employerPackagingAgreements: {
    title: "Packaging Agreements",
    ...defaultConfig,
  },
  employerPayrollAdvice: {
    title: "Payroll Advice",
    ...defaultConfig,
  },
  employerFBTReports: {
    title: "FBT Reports",
    ...defaultConfig,
  },
  employerEngagementReports: {
    title: "Engagement",
    ...defaultConfig,
  },
  employerLuxuryLeaseReports: {
    title: "Luxury Lease",
    ...defaultConfig,
  },
  employerPromotions: { title: "Promotions", ...defaultConfig },
};

type DocumentFilterInputs = {
  accounts: AutoCompleteOption[] | null;
  payrollCycle: AutoCompleteOption | null;
  dateFrom: string;
  dateTo: string;
  date2From: string;
  date2To: string;
  name: string;
  documentTypes: AutoCompleteOption[] | null;
};

export const DEFAULT_PAGE_SIZE = 10;

const accountColors = [
  "neutralWhite.main",
  "primary.light",
  "tertiaryGreen.light",
  "neutralGray.light",
  "tertiaryBlue.light",
  "tertiaryYellow.light",
  "tertiaryDarkBlue.light",
];

const DocumentList = ({
  me,
  page,
}: {
  me: User;
  page: EmployerDocumentPageName;
}) => {
  const navigate = useNavigate();
  const documentTypes = getDocumentTypesForEmployerPage(page);
  const { getScopedPageUrl } = useAuth();
  const { fetchWithAuth } = useApi();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<DocumentFilters>({
    pageSize: DEFAULT_PAGE_SIZE,
    documentTypes: documentTypes,
  });
  const [paginationHistory, setPaginationHistory] = useState<string[]>([]);

  const [accountColorMap, setAccountColorMap] = useState<
    Record<string, string>
  >({});

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<DocumentFilterInputs>({
    mode: "onTouched",
  });

  const listConfig = documentListConfigMap[page];
  const authorisedRoles = me.contactRoles
    ? filterAuthorisedRolesForPage(me.contactRoles, page)
    : [];
  const dateFrom = watch("dateFrom");
  const dateTo = watch("dateTo");
  const date2From = watch("date2From");
  const date2To = watch("date2To");

  useEffect(() => {
    if (
      me.contactRoles &&
      isAuthorisedToViewEmployerPage(me.contactRoles, page)
    ) {
      setNavigationCheck(true);
    } else {
      navigate(getScopedPageUrl("home"), { replace: true });
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [filters]);

  const documents = getDocuments(filters, { keepPreviousData: true });

  useEffect(() => {
    if (documents.isSuccess) {
      const newColorMap = { ...accountColorMap };

      documents.data.items.forEach((document) => {
        // If account does not already have a color then assign it one
        if (!newColorMap[document.accountId]) {
          const colorIndex = Object.keys(newColorMap).length % 7;
          newColorMap[document.accountId] = accountColors[colorIndex];
        }
      });

      if (
        Object.keys(newColorMap).length !== Object.keys(accountColorMap).length
      ) {
        setAccountColorMap(newColorMap);
      }
    }
  }, [documents]);

  if (documents.isError) {
    throw documents.error;
  }

  const buildFieldLabel = (fieldName: keyof APDocument, postfix: string) => {
    return `${
      listConfig.filterLabels && listConfig.filterLabels[fieldName]
        ? `${listConfig.filterLabels[fieldName]} `
        : ""
    }${postfix}`;
  };

  const handleFiltersClick = () => {
    setShowFilters(!showFilters);
  };

  const handlePrevPage = () => {
    const prevPageIndex =
      paginationHistory.length > 1 ? paginationHistory.length - 2 : undefined;
    const prevPage = prevPageIndex
      ? paginationHistory[prevPageIndex]
      : undefined;
    const otherPages = paginationHistory.slice(0, -1);

    setPaginationHistory(otherPages);
    setFilters({ ...filters, nextPageLink: prevPage });
  };

  const handleNextPage = () => {
    if (documents.data) {
      const nextLink = documents.data.nextPageLink;
      setPaginationHistory([...paginationHistory, nextLink]);
      setFilters({ ...filters, nextPageLink: nextLink });
    }
  };

  const handleSubmitFilters = (inputs: DocumentFilterInputs) => {
    setFilters({
      ...filters,
      nextPageLink: undefined,
      accountIds: inputs.accounts
        ? inputs.accounts.map((account) => account.id)
        : undefined,
      dateTo: inputs.dateTo
        ? dayjs(inputs.dateTo).endOf("day").toISOString()
        : undefined,
      dateFrom: inputs.dateFrom
        ? dayjs(inputs.dateFrom).toISOString()
        : undefined,
      date2To: inputs.date2To
        ? dayjs(inputs.date2To).endOf("day").toISOString()
        : undefined,
      date2From: inputs.date2From
        ? dayjs(inputs.date2From).toISOString()
        : undefined,
      nameSearch: inputs.name || undefined,
      documentTypes: inputs.documentTypes
        ? inputs.documentTypes.map((type) => type.id as APDocumentType)
        : documentTypes,
    });
    setPaginationHistory([]);
    setShowFilters(false);
  };

  const handleDownload = async (documentId: string, fileName: string) => {
    try {
      const pdf = await fetchWithAuth<Blob>("getDocumentPDF", documentId, {
        responseOptions: { responseType: "blob" },
      });

      downloadFile(pdf, fileName);
    } catch (e) {
      enqueueSnackbar(`There was an issue downloading the file.`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  const handleOpenUrl = (url: string) => {
    openInNewTab(url);
  };

  const renderDocumentCard = (document: APDocument) => {
    return (
      <DocumentCard
        key={document.id}
        document={document}
        onDownload={handleDownload}
        onOpenUrl={handleOpenUrl}
        titleFields={listConfig.titleFields}
        bodyFields={listConfig.bodyFields}
        expandFields={listConfig.expandFields}
        fieldLabels={listConfig.fieldLabels}
        cardColor={accountColorMap[document.accountId] || accountColorMap[0]}
      />
    );
  };

  const accountsOptions = buildAutoCompleteOptions(
    authorisedRoles,
    "accountName",
    "accountId"
  );
  accountsOptions.sort((a, b) => a.label.localeCompare(b.label));
  const documentOptions = buildAutoCompleteOptions(
    documentTypes.map((type) => {
      return { type };
    }),
    "type",
    "type"
  );

  const filterKeys = listConfig.filters;
  return (
    <PageContainer
      title={listConfig.title}
      loading={!navigationCheckComplete}
      titleButton={
        <IconButton
          size="large"
          aria-label="refresh"
          color="onSurface"
          onClick={handleFiltersClick}
        >
          <SearchOutlinedIcon />
        </IconButton>
      }
    >
      <Collapse in={showFilters}>
        <Box mb={2}>
          <APForm
            onSubmit={handleSubmit(handleSubmitFilters)}
            submitText={"Go"}
            isLoading={false}
            isError={Object.keys(errors).length > 0}
            minSpacing
            submitButtonColor="primary"
          >
            {filterKeys.includes("accounts") && accountsOptions.length > 1 && (
              <APAutoComplete
                name={"accounts"}
                label="Employers"
                options={accountsOptions}
                control={control}
                validations={{ required: true }}
                errors={errors}
                defaultValue={accountsOptions}
                setValue={setValue}
                multiple
              />
            )}

            {filterKeys.includes("documentTypes") &&
              documentTypes.length > 1 && (
                <APAutoComplete
                  name={"documentTypes"}
                  label="Document Types"
                  options={documentOptions}
                  control={control}
                  validations={{ required: true }}
                  errors={errors}
                  defaultValue={documentOptions}
                  setValue={setValue}
                  multiple
                />
              )}

            {filterKeys.includes("dateFrom") && (
              <Box
                sx={{
                  display: "flex",
                  columnGap: 2,
                }}
              >
                <APDateField
                  name="dateFrom"
                  label={buildFieldLabel("date", "From")}
                  control={control}
                  defaultValue={null}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  maxDate={dateTo ? dayjs(dateTo) : undefined}
                  clearable
                />
                {filterKeys.includes("dateTo") && (
                  <APDateField
                    name="dateTo"
                    label={buildFieldLabel("date", "To")}
                    control={control}
                    defaultValue={null}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    minDate={dateFrom ? dayjs(dateFrom) : undefined}
                    clearable
                  />
                )}
              </Box>
            )}

            {filterKeys.includes("date2From") && (
              <Box
                sx={{
                  display: "flex",
                  columnGap: 2,
                }}
              >
                <APDateField
                  name="date2From"
                  label={buildFieldLabel("date2", "From")}
                  control={control}
                  defaultValue={null}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  maxDate={date2To ? dayjs(date2To) : undefined}
                  clearable
                />
                {filterKeys.includes("date2To") && (
                  <APDateField
                    name="date2To"
                    label={buildFieldLabel("date2", "To")}
                    control={control}
                    defaultValue={null}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    minDate={date2From ? dayjs(date2From) : undefined}
                    clearable
                  />
                )}
              </Box>
            )}

            {filterKeys.includes("name") && (
              <APTextField
                name={"name"}
                label="Search"
                control={control}
                defaultValue=""
                validations={{}}
                errors={errors}
              />
            )}
          </APForm>
        </Box>
      </Collapse>
      {documents.isSuccess && navigationCheckComplete && (
        <PaginationContainer
          currentPage={paginationHistory.length + 1}
          pageSize={DEFAULT_PAGE_SIZE}
          itemCount={documents.isSuccess ? documents.data.count : 0}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
        >
          <Stack spacing={1}>
            {documents.data.items.map(renderDocumentCard)}
          </Stack>
        </PaginationContainer>
      )}
      <Box mt={2}>
        <BackButton />
      </Box>
      {documents.isFetching && <LoadingBackdrop />}
    </PageContainer>
  );
};

export default DocumentList;
