import { useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getQuote } from "../../api/quote/getQuote";
import { updateQuote } from "../../api/quote/updateQuote";
import { getVehicleOptions } from "../../api/vehicle/getVehicleOptions";
import PageContainer from "../../components/PageContainer";
import { downloadFileFromUrl } from "../../helpers/download";
import QuotePersonaliser from "../components/QuotePersonaliser";

const PersonaliseQuote = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm();
  const { fetchWithAuth } = useApi();
  const { getScopedPageUrl } = useAuth();

  if (!quoteId) {
    // TODO: improve client side error messaging
    throw new Error("quote id param is misisng");
  }

  const quote = getQuote(quoteId);

  const { mutateAsync, isLoading: isSubmitting } = updateQuote(quoteId);

  const variantId = quote.data?.vehicle.variantId;
  const vehicleOptions = getVehicleOptions(variantId || "", {
    disabled: !variantId,
  });

  if (quote.isError) {
    throw quote.error;
  }

  if (vehicleOptions.isError) {
    throw vehicleOptions.error;
  }

  useEffect(() => {
    if (quote?.isSuccess) {
      quote.data.catchEEquipmentIds.forEach((id) => {
        setValue(`options.${id}`, true);
      });
    }
  }, [quote.isSuccess]);

  const isLoading = vehicleOptions.isFetching || quote.isFetching;
  const isSuccess = vehicleOptions.isSuccess && quote.isSuccess;

  const onSubmit: SubmitHandler<FieldValues> = async ({ options }) => {
    if (quote.isSuccess) {
      const optionalEquipment: string[] = [];
      const oldEquipment = quote.data?.catchEEquipmentIds;

      if (options) {
        Object.keys(options).forEach((optionId) => {
          if (options[optionId]) {
            optionalEquipment.push(optionId);
          }
        });
      }

      const hasChanged =
        optionalEquipment.length !== oldEquipment.length ||
        !optionalEquipment.every(
          (equipmentId) => oldEquipment.indexOf(equipmentId) !== 1
        );

      if (hasChanged) {
        await mutateAsync({ optionalEquipment });
      }

      navigate(getScopedPageUrl("viewQuote", quoteId));
    }
  };

  const onEdit = async (values: FieldValues) => {
    await onSubmit(values);
    navigate(getScopedPageUrl("editQuote", quoteId));
  };

  const onOptionDownload = async (url: string, filename: string) => {
    const pdfSrc = await fetchWithAuth<string>("getFile", url, {
      responseOptions: { responseType: "fileUrl" },
    });
    downloadFileFromUrl(pdfSrc, filename);
  };

  // TODO: sort the vehicle images
  return (
    <PageContainer title="Vehicle Personalisation" loading={isLoading}>
      {isSuccess && (
        <QuotePersonaliser
          vehicleMake={quote.data.vehicle.make}
          vehicleModel={quote.data.vehicle.model}
          vehicleYear={quote.data.vehicle.year}
          vehicleVariantDescription={quote.data.vehicle.variantLongName}
          vehicleImages={quote.data.vehicle.images.map(
            (vehicle) => vehicle.href
          )}
          basePrice={quote.data.listPrice}
          series={quote.data.vehicle.series}
          body={quote.data.vehicle.body}
          seats={quote.data.vehicle.seats}
          transmission={quote.data.vehicle.transmission}
          drive={quote.data.vehicle.driveType}
          fuelType={quote.data.vehicle.fuelType}
          releaseDate={quote.data.vehicle.year}
          co2Emissions={quote.data.vehicle.co2Emissions}
          ancapRating={quote.data.vehicle.ancapRating}
          vehicleOptions={vehicleOptions.data}
          standardFeatures={quote.data.vehicle.standardEquipment}
          savingsPercentage={quote.data.savings}
          term={quote.data.term}
          catchEQuoteUrl={quote.data.catchEQuoteUrl}
          handleEdit={handleSubmit(onEdit)}
          control={control}
          handleOptionDownload={onOptionDownload}
          handleSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
        />
      )}
    </PageContainer>
  );
};

export default PersonaliseQuote;
