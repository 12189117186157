import { Navigate, useParams, useSearchParams } from "react-router-dom";

import { useAuth } from "../api/AuthProvider";
import { PageDirectoryEntryName } from "../api/PageDirectory";

const CrmLinkRouter = () => {
  const { destinationId } = useParams();
  const [searchParams] = useSearchParams();
  const { getScopedPageUrl } = useAuth();
  const id = searchParams.get("id");

  if (!destinationId) {
    // TODO: improve client side error messaging
    throw new Error("destination id param is misisng");
  }

  if (!id) {
    // TODO: improve client side error messaging
    throw new Error("id query param are missing");
  }

  return (
    <Navigate
      to={getScopedPageUrl(destinationId as PageDirectoryEntryName, id)}
    />
  );
};

export default CrmLinkRouter;
