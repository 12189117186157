import { useQuery } from "react-query";

import { Faq } from "../../types/Faq";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getFaq = (faqId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["faqs", faqId],
    queryFn: (): Promise<Faq> => {
      return fetchWithAuth<Faq>("getFaq", faqId);
    },
    enabled: isQueryEnabled(options),
  });
};
