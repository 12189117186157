import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import APAutoComplete from "../../../components/form/APAutoComplete";
import APDateField from "../../../components/form/APDateField";
import APFileUpload from "../../../components/form/APFileUpload";
import APRadioGroup from "../../../components/form/APRadioGroup";
import APTextField from "../../../components/form/APTextField";
import {
  showIdentityPassportUploadField,
  showIdentityVisaDescriptionFields,
  showIdentityVisaUploadField,
} from "../../../helpers/financeApplication";
import {
  AustralianResidencyStatus,
  DriversLicenseType,
} from "../../../types/Dossier";
import { stateOptions } from "../../../types/State";
import { FinanceApplicationInputs } from "..";

export const licenseTypeOptions: {
  id: DriversLicenseType;
  label: string;
}[] = [
  { id: "full", label: "Full" },
  { id: "provisional", label: "Provisional" },
  { id: "learner", label: "Learner" },
  { id: "heavy-vehicle", label: "Heavy Vehicle" },
  { id: "none", label: "None" },
];

export const residencyOptions: {
  id: AustralianResidencyStatus;
  label: string;
}[] = [
  { id: "citizen", label: "Australian Citizen" },
  { id: "nz-citizen", label: "New Zealand Citizen" },
  { id: "permanent-resident", label: "Permanent Resident" },
  { id: "working-visa", label: "Working Visa" },
  { id: "other", label: "Other" },
];

const FinanceApplicationIdentityForm = ({
  control,
  errors,
  watch,
  setValue,
  setError,
  clearErrors,
  readOnly,
  highlightRequired,
}: {
  control: Control<FinanceApplicationInputs>;
  errors: FieldErrors<FinanceApplicationInputs>;
  watch: UseFormWatch<FinanceApplicationInputs>;
  setValue: UseFormSetValue<FinanceApplicationInputs>;
  setError: UseFormSetError<FinanceApplicationInputs>;
  clearErrors: UseFormClearErrors<FinanceApplicationInputs>;
  readOnly: boolean;
  highlightRequired: boolean;
}) => {
  const watchResidency = watch("residencyStatus");
  const showPassportUploadField =
    showIdentityPassportUploadField(watchResidency);
  const showVisaUploadField = showIdentityVisaUploadField(watchResidency);
  const showVisaDescriptionFields =
    showIdentityVisaDescriptionFields(watchResidency);

  // If user selects a residency that doesn't require visa
  // details then clear the visa fields
  useEffect(() => {
    if (!showPassportUploadField) {
      setValue("passportImage", null);
    }
    if (!showVisaUploadField) {
      setValue("visaImage", null);
    }
    if (!showVisaDescriptionFields) {
      setValue("visaType", "");
      setValue("visaExpiry", null);
    }
  }, [watchResidency]);

  return (
    <Stack spacing={2}>
      <APTextField<FinanceApplicationInputs>
        name="driversLicenseNumber"
        label="Drivers License Number"
        control={control}
        validations={{
          maxLength: 20,
          formatValidation: /[a-zA-Z0-9]+/,
        }}
        errors={errors}
        defaultValue=""
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APTextField<FinanceApplicationInputs>
        name="driversLicenseCardNumber"
        label="Drivers License Card Number"
        control={control}
        validations={{
          maxLength: 20,
          formatValidation: /[a-zA-Z0-9]+/,
        }}
        errors={errors}
        defaultValue=""
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APAutoComplete
        name="driversState"
        label="Drivers License State"
        options={stateOptions}
        control={control}
        validations={{}}
        errors={errors}
        defaultValue={null}
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APAutoComplete
        name="driversLicenseType"
        label="License Type"
        options={licenseTypeOptions}
        control={control}
        validations={{}}
        errors={errors}
        defaultValue={null}
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APFileUpload
        name="driversLicenseFrontImage"
        label="Drivers License Front Image"
        control={control}
        errors={errors}
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APFileUpload
        name="driversLicenseBackImage"
        label="Drivers License Back Image"
        control={control}
        errors={errors}
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <APRadioGroup
        name="residencyStatus"
        label="Australian Residency Status"
        validations={{}}
        errors={errors}
        control={control}
        defaultValue={null}
        options={residencyOptions}
        showRequired={true}
        highlightOnEmpty={highlightRequired}
        readOnly={readOnly}
      />

      <Stack spacing={2}>
        {showVisaDescriptionFields && (
          <>
            <APTextField<FinanceApplicationInputs>
              name="visaType"
              label="Visa Type"
              control={control}
              validations={{
                maxLength: 100,
              }}
              errors={errors}
              defaultValue=""
              placeholder="Title/code and description of visa"
              showRequired={true}
              highlightOnEmpty={highlightRequired}
              readOnly={readOnly}
            />

            <APDateField
              name="visaExpiry"
              label="Visa Expiry"
              control={control}
              defaultValue={null}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              showRequired={true}
              highlightOnEmpty={highlightRequired}
              readOnly={readOnly}
            />
          </>
        )}
        {showVisaUploadField && (
          <APFileUpload
            name="visaImage"
            label="Visa"
            control={control}
            errors={errors}
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
          />
        )}
        {showPassportUploadField && (
          <APFileUpload
            name="passportImage"
            label="Passport"
            control={control}
            errors={errors}
            showRequired={true}
            highlightOnEmpty={highlightRequired}
            readOnly={readOnly}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default FinanceApplicationIdentityForm;
