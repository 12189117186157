import { AutoCompleteOption } from "../components/form/APAutoComplete";
export const leadSourceOptions: AutoCompleteOption[] = [
  { id: "5", label: "Internet Search" },
  { id: "4", label: "Social Media" },
  { id: "2", label: "My Employer" },
  { id: "1", label: "Auto-UX Customer" },
  { id: "3", label: "Referral" },
  { id: "100000001", label: "Staff Presentation" },
  { id: "7", label: "Event" },
  { id: "9", label: "Other" },
];
