import { ReactNode } from "react";

import { useAuth } from "../api/AuthProvider";
import { ApplicationScope } from "../types/App";

interface AppScopeProps {
  children: ReactNode;
  scope: ApplicationScope;
}

const RestrictedAppScope = ({ children, scope }: AppScopeProps) => {
  const { inAppScope } = useAuth();
  const isInAppScope = inAppScope(scope);

  return <>{isInAppScope && children}</>;
};

export default RestrictedAppScope;
