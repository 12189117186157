import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type CreateBankAccountPayload = {
  financialInstitution: string;
  accountName: string;
  bsb: string;
  accountNumber: string;
  driverDevice: string;
};

export const createMyBankAccount = (
  dossierId?: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();
  const invalidateList = [["bankAccount"]];

  if (dossierId) {
    invalidateList.push(["dossier", dossierId], ["dossierStatus", dossierId]);
  }

  return useMutation({
    mutationFn: (payload: CreateBankAccountPayload): Promise<boolean> => {
      const fetchOptions = {
        requestOptions: { method: "post", body: JSON.stringify(payload) },
      };

      if (dossierId) {
        return fetchWithAuth<boolean>(
          "createDossierBankAccount",
          dossierId,
          fetchOptions
        );
      } else {
        return fetchWithAuth<boolean>(
          "createMyBankAccount",
          undefined,
          fetchOptions
        );
      }
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () => defaultOnMutateSettled(invalidateList),
  });
};
