import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import { getFile } from "../api/file/getFile";

const APImageWithLoader = ({
  imageUrl,
  width,
  height,
  minHeight,
}: {
  imageUrl: string;
  width: string;
  height: string;
  minHeight: string;
}) => {
  const imageSrc = getFile(imageUrl, true);

  return (
    <Box
      position="relative"
      width={width}
      maxHeight={height}
      minHeight={minHeight}
    >
      {imageSrc.isLoading && (
        <Skeleton variant="rectangular" width={width} height={height} />
      )}
      {!imageSrc.isLoading && (
        <Box
          component="img"
          src={imageSrc.data}
          sx={{ maxWidth: width, maxHeight: height }}
        ></Box>
      )}
    </Box>
  );
};

export default APImageWithLoader;
