import { useQuery } from "react-query";

import { User } from "../../types/User";
import { useApi } from "../ApiProvider";

export const getMyUser = (employerId?: string) => {
  const { fetchWithAuth } = useApi();
  const queryParams = employerId ? { EmployerID: employerId } : undefined;

  return useQuery(["user"], (): Promise<User> => {
    return fetchWithAuth<User>("getMyUser", undefined, { queryParams });
  });
};
