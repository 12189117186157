import { useMutation } from "react-query";

import { Dossier } from "../../types/Dossier";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type SetPrimaryQuotePayload = {
  quoteId: string;
};

export const setPrimaryQuote = (
  dossierId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
    optimisiticUpdateOnMutate,
    getDataByQueryKey,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: SetPrimaryQuotePayload): Promise<boolean> => {
      return fetchWithAuth<boolean>("setPrimaryQuote", dossierId, {
        requestOptions: { method: "post", body: JSON.stringify(payload) },
      });
    },
    onMutate: async (payload) => {
      const queryKey = ["dossier", dossierId];
      const dossier = getDataByQueryKey(queryKey) as Dossier;
      const updatedOpportunity = dossier.opportunity
        ? { ...dossier.opportunity, primaryQuoteId: payload.quoteId }
        : undefined;
      const updatedDossier: Dossier = {
        ...dossier,
        opportunity: updatedOpportunity,
      };

      optimisiticUpdateOnMutate(["dossier", dossierId], updatedDossier);
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () => defaultOnMutateSettled([["dossiers"]]),
  });
};
