import { useQuery,  } from 'react-query';

import { DetailedDossierStatus } from '../../types/Dossier';
import { useApi } from '../ApiProvider';
import { APQueryOptions, useAPQuery } from '../APQueryProvider';

export const getEmptyDossierStatus = (options: APQueryOptions = {}) => {  
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["emptyDossierStatus"],
    queryFn: (): Promise<DetailedDossierStatus> => {
      return fetchWithAuth<DetailedDossierStatus>("getEmptyDossierStatus");
    },
    enabled: isQueryEnabled(options)
  });
};

