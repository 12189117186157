import PhoneIcon from "@mui/icons-material/Call";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../api/AuthProvider";
import { getMyDossiers } from "../api/dossier/getMyDossiers";
import { qualifyNewLead } from "../api/user/qualifyNewLead";
import { hasOpenDossier } from "../helpers/dossiers";
import { hasAccountRoles } from "../helpers/user";
import { User } from "../types/User";
import LoadingBackdrop from "./LoadingBackdrop";
import RestrictedAppScope from "./RestrictedAppScope";

function NavBar({ me }: { me: User }) {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const { getScopedPageUrl } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dossiersResponse = getMyDossiers({ disabled: !me.crmContactId });

  if (dossiersResponse.isError) {
    throw dossiersResponse.error;
  }

  const { mutateAsync, isLoading: isUpdating } = qualifyNewLead();

  const { pathname, search } = location;
  const isUserInfo = pathname === "/user";
  const isInitialQuote =
    !!pathname.match(/\/quote\/create/) && !!search.match("limitedFields=true");
  const hideMyCar = isUserInfo || isInitialQuote;
  const hideDirectDebit = isUserInfo;
  const isEmployer = me.contactRoles && hasAccountRoles(me.contactRoles);
  const canCreateDossier =
    dossiersResponse.isSuccess &&
    !hasOpenDossier(dossiersResponse.data) &&
    !isUserInfo;

  const handleNewLead = async () => {
    handleClose();
    await mutateAsync();
    navigate(`${getScopedPageUrl("viewDossiers")}?openFirst=true`);
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar sx={{ height: 80, py: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Link to={getScopedPageUrl("home")}>
              <Box
                component="img"
                sx={{ height: 42, display: "flex" }}
                src="/auto-ux.svg"
              ></Box>
            </Link>
          </Box>
          <Button
            href="tel:1800288689"
            color="secondary"
            variant="contained"
            disableElevation={true}
            sx={{
              alignSelf: "center",
              p: 1.25,
              mr: 1,
              minWidth: "initial",
            }}
            size="small"
            aria-label="menu"
          >
            <PhoneIcon sx={{ fontSize: "1.25rem" }} />
          </Button>
          <IconButton
            sx={{ alignSelf: "center", mr: "-5px" }}
            size="small"
            color="primary"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon sx={{ fontSize: "2rem" }} />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
            sx={{ ml: 2, mt: 1 }}
          >
            {isEmployer && (
              <RestrictedAppScope scope="external">
                <Link to={getScopedPageUrl("employerHome")}>
                  <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                    Employer
                  </MenuItem>
                </Link>
              </RestrictedAppScope>
            )}
            {canCreateDossier && (
              <RestrictedAppScope scope="external">
                <MenuItem sx={{ pr: 4 }} onClick={handleNewLead}>
                  New Car
                </MenuItem>
              </RestrictedAppScope>
            )}
            {!hideMyCar && (
              <RestrictedAppScope scope="external">
                <Link to={getScopedPageUrl("viewDossiers")}>
                  <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                    My Car
                  </MenuItem>
                </Link>
              </RestrictedAppScope>
            )}
            {!hideDirectDebit && (
              <RestrictedAppScope scope="external">
                <Link to={getScopedPageUrl("userDirectDebit")}>
                  <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                    Set My Bank Account
                  </MenuItem>
                </Link>
              </RestrictedAppScope>
            )}
            <Link to="https://www.auto-ux.io/privacy-policy/" target="_blank">
              <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                Privacy
              </MenuItem>
            </Link>
            <Link to="https://www.auto-ux.io/contact/" target="_blank">
              <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                Contact Us
              </MenuItem>
            </Link>
            <Box
              onClick={() =>
                keycloak.logout({ redirectUri: "https://auto-ux.io" })
              }
            >
              <MenuItem sx={{ pr: 4 }} onClick={handleClose}>
                Sign Out
              </MenuItem>
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
      {isUpdating && <LoadingBackdrop />}
    </Box>
  );
}
export default NavBar;
