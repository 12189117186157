import { useMutation } from "react-query";

import { KeycloakUserInfo } from "../../types/User";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type SetUserMFAPayload = {
  enabled: boolean;
};

export const setUserMFA = (contactId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    getDataByQueryKey,
    optimisiticUpdateOnMutate,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: SetUserMFAPayload): Promise<boolean> => {
      return fetchWithAuth<boolean>("setUserMFA", contactId, {
        requestOptions: { method: "post", body: JSON.stringify(payload) },
      });
    },
    onMutate: async (payload) => {
      const queryKey = ["keycloakUserInfo", contactId];
      const userInfo = getDataByQueryKey(queryKey) as KeycloakUserInfo;
      const updatedUserInfo: KeycloakUserInfo = {
        ...userInfo,
      };
      if (updatedUserInfo.user) {
        updatedUserInfo.user.mfaEnabled = payload.enabled;
      }

      optimisiticUpdateOnMutate(
        ["keycloakUserInfo", contactId],
        updatedUserInfo
      );
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
  });
};
