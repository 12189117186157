import { useMutation } from "react-query";

import { User } from "../../types/User";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type SetMyMFAPayload = {
  enabled: boolean;
};

export const setMyMFA = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    getDataByQueryKey,
    optimisiticUpdateOnMutate,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: SetMyMFAPayload): Promise<boolean> => {
      return fetchWithAuth<boolean>("setMyMFA", undefined, {
        requestOptions: { method: "post", body: JSON.stringify(payload) },
      });
    },
    onMutate: async () => {
      const queryKey = ["user"];
      const user = getDataByQueryKey(queryKey) as User;
      const updatedUser: User = {
        ...user,
        shouldPromptMFA: false,
      };

      optimisiticUpdateOnMutate(["user"], updatedUser);
    },
    onError: defaultOnMutateError(),
    onSuccess: (_, payload) => {
      const adjustedOptions = { ...options };

      // If MFA is not being enabled, then don't show the sucess message
      if (!payload.enabled && adjustedOptions.mutationOptions) {
        adjustedOptions.mutationOptions.successAlertMessage = undefined;
      }

      const success = defaultOnMutateSuccess(adjustedOptions);
      success && success();
    },
  });
};
