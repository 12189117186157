import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import { getFaq } from "../../api/faq/getFaq";
import ModalContainer from "../../components/ModalContainer";
import FaqDetails from "../components/FaqDetails";

const ViewFaq = () => {
  const { faqId } = useParams();

  if (!faqId) {
    // TODO: improve client side error messaging
    throw new Error("faq id param is misisng");
  }

  const faq = getFaq(faqId);

  if (faq && faq.isError) {
    throw faq.error;
  }

  const isLoading = faq.isLoading;
  const isSuccess = faq.isSuccess;

  return (
    <ModalContainer loading={isLoading}>
      {isSuccess && (
        <Box>
          {faq.data && <FaqDetails faq={faq.data} appLocationId={null} />}
        </Box>
      )}
    </ModalContainer>
  );
};

export default ViewFaq;
