import { useQuery } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getFile = (
  filePath: string,
  isImage?: boolean,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();
  const queryParams = isImage ? { isImage: "true" } : undefined;

  return useQuery({
    queryKey: ["file", filePath],
    queryFn: (): Promise<string> => {
      return fetchWithAuth<string>("getFile", filePath, {
        responseOptions: { responseType: "fileUrl" },
        queryParams,
      });
    },
    enabled: isQueryEnabled(options),
  });
};
