import { useQuery } from "react-query";

import { FaqVote } from "../../types/Faq";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getFaqVote = (
  faqId: string,
  appLocationId: string | null,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();
  const queryParams = appLocationId ? { appLocationId } : undefined;

  return useQuery({
    queryKey: ["faqVote", faqId, appLocationId],
    queryFn: (): Promise<FaqVote | null> => {
      return fetchWithAuth<FaqVote | null>("getFaqVote", faqId, {
        queryParams,
      });
    },
    enabled: isQueryEnabled(options),
  });
};
