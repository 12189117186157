import { useQuery } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const hasSurveyAwaitingResponse = (
  surveyId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["survey-awaiting-response", surveyId],
    queryFn: (): Promise<boolean> => {
      return fetchWithAuth<boolean>("hasSurveyAwaitingResponse", surveyId);
    },
    enabled: isQueryEnabled(options),
  });
};
