import { AutoCompleteOption } from "../components/form/APAutoComplete";
import {
  ExistingAssetAndLiability,
  FinanceApplicationInputs,
  NewAssetAndLiability,
  RequiredFields,
} from "../dossier/financeApplication";
import {
  AddressResidenceStatus,
  ASSET_AND_LIABILITY_ID_PROPERTY,
  AssetAndLiabilityType,
  AustralianResidencyStatus,
} from "../types/Dossier";

export const showIdentityPassportUploadField = (
  residencyStatus: AustralianResidencyStatus | null
) => {
  return residencyStatus && residencyStatus !== "citizen";
};

export const showIdentityVisaUploadField = (
  residencyStatus: AustralianResidencyStatus | null
) => {
  return (
    residencyStatus &&
    residencyStatus !== "citizen" &&
    residencyStatus !== "nz-citizen"
  );
};

export const showIdentityVisaDescriptionFields = (
  residencyStatus: AustralianResidencyStatus | null
) => {
  return (
    residencyStatus &&
    residencyStatus !== "citizen" &&
    residencyStatus !== "nz-citizen" &&
    residencyStatus !== "permanent-resident"
  );
};

export const showResidenceLandlordFields = (
  residenceStatus: AddressResidenceStatus
) => {
  return residenceStatus === "rent";
};

export const showResidencePreviousAddress = (currentYearsAt: string) => {
  return !!currentYearsAt && parseInt(currentYearsAt) < 2;
};

export const showEmploymentTaxReturns = (
  employmentType: AutoCompleteOption | null
) => {
  return !!employmentType && employmentType.id === "director";
};

export const showEmploymentPreviousEmployment = (currentYearsAt: string) => {
  return !!currentYearsAt && parseInt(currentYearsAt) < 2;
};

export const isRentIncomeRequired = (
  residenceStatus: AddressResidenceStatus
) => {
  return residenceStatus === "rent" || residenceStatus === "boarding";
};

export const hasAssetOrLiability = (
  assetAndLiabilityTypes: AssetAndLiabilityType[],
  itemId: string
) => {
  const selectedType =
    assetAndLiabilityTypes.find((type) => `${type.typeId}` === itemId) || null;
  const hasAsset = !!selectedType && selectedType.isAsset;
  const hasLiability = !!selectedType && selectedType.isLiability;

  return [hasAsset, hasLiability];
};

export const showLiabilityFinancier = (owing: string) => {
  return !!owing && parseInt(owing) > 0;
};

export const showAssetIncomeAndMortgage = (type: AutoCompleteOption | null) => {
  return !!type && type.id === `${ASSET_AND_LIABILITY_ID_PROPERTY}`;
};

export const isCreditCardProvidersRequired = (totalCreditCardLimit: string) => {
  return !!totalCreditCardLimit && parseInt(totalCreditCardLimit) > 0;
};

export type MonthlyIncomeTotals = {
  totalIncome: number;
  totalExpenses: number;
  netIncome: number;
};

export const calculateIncomeTotals = (
  annualSalary: string,
  annualIncome: string,
  propertyIncome: string,
  investmentIncome: string,
  govtBenefitIncome: string,
  otherIncome: string,
  rentMonthly: string,
  mortgageMonthly: string,
  vehicleMonthly: string,
  educationMonthly: string,
  generalExpenseMonthly: string,
  otherExpensesMonthly: string
): MonthlyIncomeTotals => {
  const totalIncome = Math.round(
    [
      annualSalary,
      annualIncome,
      propertyIncome,
      investmentIncome,
      govtBenefitIncome,
      otherIncome,
    ].reduce((sum: number, next: string) => {
      if (next) {
        return sum + parseInt(next) / 12;
      }
      return sum;
    }, 0)
  );

  const totalExpenses = Math.round(
    [
      rentMonthly,
      mortgageMonthly,
      vehicleMonthly,
      educationMonthly,
      generalExpenseMonthly,
      otherExpensesMonthly,
    ].reduce((sum: number, next: string) => {
      if (next) {
        return sum + parseInt(next);
      }
      return sum;
    }, 0)
  );

  const netIncome = totalIncome - totalExpenses;

  return {
    totalIncome,
    totalExpenses,
    netIncome,
  };
};

const calculateTotalAmount = (
  existingAssetsAndLiabilities: ExistingAssetAndLiability[],
  newAssetsAndLiabilities: NewAssetAndLiability[],
  amountType: "asset" | "liability",
  listType: "existing" | "new"
) => {
  const fieldName = amountType === "asset" ? "assetValue" : "liabilityValue";
  const list =
    listType === "existing"
      ? existingAssetsAndLiabilities
      : newAssetsAndLiabilities;
  const totalAmount = list
    ? list.reduce((acc, item) => {
        return (
          acc +
          (!item.toBeRemoved && item[fieldName] ? parseInt(item[fieldName]) : 0)
        );
      }, 0)
    : 0;
  return totalAmount;
};

export type AssetAndLiabilityTotals = {
  totalAssets: number;
  totalLiabilities: number;
  totalNetWorth: number;
};

export const calculateAssetAndLiabilityTotals = (
  existingAssetsAndLiabilities: ExistingAssetAndLiability[],
  newAssetsAndLiabilities: NewAssetAndLiability[],
  totalCreditCardLimit: string
): AssetAndLiabilityTotals => {
  const totalAssets =
    calculateTotalAmount(
      existingAssetsAndLiabilities,
      newAssetsAndLiabilities,
      "asset",
      "existing"
    ) +
    calculateTotalAmount(
      existingAssetsAndLiabilities,
      newAssetsAndLiabilities,
      "asset",
      "new"
    );
  const totalLiabilities =
    calculateTotalAmount(
      existingAssetsAndLiabilities,
      newAssetsAndLiabilities,
      "liability",
      "existing"
    ) +
    calculateTotalAmount(
      existingAssetsAndLiabilities,
      newAssetsAndLiabilities,
      "liability",
      "new"
    ) +
    (totalCreditCardLimit ? parseInt(totalCreditCardLimit) : 0);
  const totalNetWorth = totalAssets - totalLiabilities;

  return {
    totalAssets,
    totalLiabilities,
    totalNetWorth,
  };
};

export const getRequiredFields = (
  data: FinanceApplicationInputs,
  assetAndLiabilityTypes: AssetAndLiabilityType[]
): RequiredFields => {
  const requiredFields: RequiredFields = {
    personal: [
      "firstName",
      "lastName",
      "dateOfBirth",
      "mobile",
      "numberOfDependents",
      "maritalStatus",
    ],
    identity: [
      "driversLicenseNumber",
      "driversLicenseCardNumber",
      "driversState",
      "driversLicenseType",
      "driversLicenseFrontImage",
      "driversLicenseBackImage",
      "residencyStatus",
    ],
    residence: [
      "currentStreetAddress",
      "currentSuburb",
      "currentState",
      "currentPostcode",
      "currentResidenceStatus",
      "currentYearsAt",
    ],
    employment: [
      "currentEmployerName",
      "currentEmploymentType",
      "currentJobTitle",
      "currentEmployerContactName",
      "currentEmployerContactPhone",
      "currentEmploymentYearsAt",
      "annualSalaryTotal",
      "latestPayslipImage",
      "prevPayslipImage",
      "annualSalaryPerPay",
      "annualSalaryPayFrequency",
    ],
    income: [
      "generalExpensesAmount",
      "generalExpensesFrequency",
      "acceptTerms",
    ],
    assetsAndLiabilities: ["totalCreditCardLimit", "totalCreditCardBalance"],
    existingAssetsAndLiabilities: [],
    newAssetsAndLiabilities: [],
  };

  // Conditional Identity Fields
  if (showIdentityPassportUploadField(data.residencyStatus)) {
    requiredFields.identity.push("passportImage");
  }
  if (showIdentityVisaUploadField(data.residencyStatus)) {
    requiredFields.identity.push("visaImage");
  }
  if (showIdentityVisaDescriptionFields(data.residencyStatus)) {
    requiredFields.identity.push("visaType", "visaExpiry");
  }

  // Conditional Residency fields
  if (showResidenceLandlordFields(data.currentResidenceStatus)) {
    requiredFields.residence.push("currentLandlord", "currentLandlordPhone");
  }
  if (showResidencePreviousAddress(data.currentYearsAt)) {
    requiredFields.residence.push(
      "previousStreetAddress",
      "previousSuburb",
      "previousState",
      "previousYearsAt"
    );
  }

  // Conditional Employment fields
  if (showEmploymentTaxReturns(data.currentEmploymentType)) {
    requiredFields.employment.push("lastTaxReturnImage", "prevTaxReturnImage");
  }
  if (showEmploymentPreviousEmployment(data.currentEmploymentYearsAt)) {
    requiredFields.employment.push(
      "previousEmployerName",
      "previousJobTitle",
      "previousEmployerContactName",
      "previousEmployerContactPhone",
      "previousEmploymentYearsAt"
    );
  }

  // Conditional Income fields
  if (data.spouseIncludeIncome) {
    requiredFields.income.push(
      "spouseAnnualIncome",
      "spouseSalaryPerPay",
      "spousePayFrequency",
      "spouseEmploymentType",
      "spouseLatestPayslipImage"
    );

    if (showEmploymentTaxReturns(data.spouseEmploymentType)) {
      requiredFields.income.push(
        "spouseLastTaxReturnImage",
        "spousePrevTaxReturnImage"
      );
    }
  }
  if (isRentIncomeRequired(data.currentResidenceStatus)) {
    requiredFields.income.push("rentAmount", "rentFrequency");
  }

  // Conditional Assets and Liability fields
  if (data.assetsAndLiabilities) {
    data.assetsAndLiabilities.forEach((item, index) => {
      if (!item.toBeRemoved) {
        const [hasAsset, hasLiability] = item.type
          ? hasAssetOrLiability(assetAndLiabilityTypes, item.type.id)
          : [false, false];
        requiredFields.existingAssetsAndLiabilities[index] = [];
        requiredFields.existingAssetsAndLiabilities[index].push("description");
        requiredFields.existingAssetsAndLiabilities[index].push("type");

        if (hasAsset) {
          requiredFields.existingAssetsAndLiabilities[index].push("assetValue");
        }

        if (hasLiability) {
          requiredFields.existingAssetsAndLiabilities[index].push(
            "liabilityValue"
          );
          if (showLiabilityFinancier(item.liabilityValue)) {
            requiredFields.existingAssetsAndLiabilities[index].push(
              "financier"
            );
          }
        }

        if (showAssetIncomeAndMortgage(item.type)) {
          requiredFields.existingAssetsAndLiabilities[index].push(
            "incomeMonthly",
            "mortgageMonthly"
          );
        }
      }
    });
  }
  if (data.newAssetsAndLiabilities) {
    data.newAssetsAndLiabilities.forEach((item, index) => {
      if (!item.toBeRemoved) {
        const [hasAsset, hasLiability] = item.type
          ? hasAssetOrLiability(assetAndLiabilityTypes, item.type.id)
          : [false, false];
        requiredFields.newAssetsAndLiabilities[index] = [];
        requiredFields.newAssetsAndLiabilities[index].push("description");
        requiredFields.newAssetsAndLiabilities[index].push("type");

        if (hasAsset) {
          requiredFields.newAssetsAndLiabilities[index].push("assetValue");
        }

        if (hasLiability) {
          requiredFields.newAssetsAndLiabilities[index].push("liabilityValue");

          if (showLiabilityFinancier(item.liabilityValue)) {
            requiredFields.newAssetsAndLiabilities[index].push("financier");
          }
        }

        if (showAssetIncomeAndMortgage(item.type)) {
          requiredFields.newAssetsAndLiabilities[index].push(
            "incomeMonthly",
            "mortgageMonthly"
          );
        }
      }
    });
  }
  if (isCreditCardProvidersRequired(data.totalCreditCardLimit)) {
    requiredFields.assetsAndLiabilities.push("creditCardProviders");
  }

  return requiredFields;
};
