import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { Location, useNavigate, useSearchParams } from "react-router-dom";

import { setMyMFA } from "../../api/user/setMyMFA";
import ModalContainer from "../../components/ModalContainer";
import { removeModalParam } from "../../helpers/routes";
import { User } from "../../types/User";

const EnableMFA = ({
  me,
  appLocation,
}: {
  me: User;
  appLocation: Location<unknown>;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const closeSearhParams = removeModalParam(searchParams);
  const closeModalPath: string = `${appLocation.pathname}${
    closeSearhParams.size > 0 ? `?${closeSearhParams.toString()}` : ""
  }`;

  const { mutateAsync, isLoading: isUpdating } = setMyMFA({
    mutationOptions: {
      successNavPath: closeModalPath,
      successAlertMessage: "Email MFA protection is now enabled.",
      successReplaceHistory: true,
    },
  });

  const handleSetMFA = (enabled: boolean) => {
    mutateAsync({ enabled });
  };

  useEffect(() => {
    if (!me.shouldPromptMFA) {
      navigate(closeModalPath, { replace: true });
    }
  }, [me]);

  return (
    <ModalContainer
      title="Protect My Identity"
      loading={isUpdating || !me.shouldPromptMFA}
    >
      <Stack spacing={3}>
        <Typography variant="bodyMedium">
          Would you like to enable email multi-factor authentication (MFA)
          protection?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="large"
            color="primary"
            variant="outlined"
            disableElevation={true}
            onClick={() => handleSetMFA(false)}
          >
            No Thanks
          </Button>

          <Button
            size="large"
            color="primary"
            variant="contained"
            disableElevation={true}
            onClick={() => handleSetMFA(true)}
          >
            Enable MFA
          </Button>
        </Box>
      </Stack>
    </ModalContainer>
  );
};

export default EnableMFA;
