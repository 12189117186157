import { useQuery } from "react-query";

import { Contact } from "../../types/Contact";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getContact = (contactId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["contact", contactId],
    queryFn: (): Promise<Contact> => {
      return fetchWithAuth<Contact>("getContact", contactId);
    },
    enabled: isQueryEnabled(options),
  });
};
