import { formatDate, now } from "./dates";

export const downloadFileFromUrl = (fileUrl: string, filename: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(fileUrl);
};

export const downloadFile = (file: Blob, filename: string) => {
  const fileUrl = window.URL.createObjectURL(file);

  return downloadFileFromUrl(fileUrl, filename);
};

export const buildQuoteFilename = (
  dossierIdentity: string,
  make: string,
  model: string,
  term: string
) => {
  const downloadDate = formatDate(now().toString(), "YYYY-MM-DD");

  return `${dossierIdentity} ${make} ${model} ${term}mth ${downloadDate}.pdf`;
};

export const buildPackagingAgreementFilename = (dossierIdentity: string) => {
  const downloadDate = formatDate(now().toString(), "YYYY-MM-DD");

  return `${dossierIdentity} Packaging Agreement ${downloadDate}.pdf`;
};
