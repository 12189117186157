import { useMutation } from "react-query";

import { Quote } from "../../types/Quote";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type SetQuoteVisibilityPayload = {
  dossierId: string;
  quoteId: string;
  visibility: boolean;
};

export const setQuoteVisibility = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    optimisiticUpdateOnMutate,
    getDataByQueryKey,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: SetQuoteVisibilityPayload): Promise<boolean> => {
      return fetchWithAuth<boolean>("setQuoteVisibility", payload.quoteId, {
        requestOptions: { method: "post", body: JSON.stringify(payload) },
      });
    },
    onMutate: async (payload) => {
      const queryKey = ["quotes", payload.dossierId];
      const quotes = getDataByQueryKey(queryKey) as Quote[];
      const quoteIndex = quotes.findIndex(
        (quote) => quote.crmQuoteId === payload.quoteId
      );
      const quoteToUpdate = quotes[quoteIndex];
      const updatedQuotes = [...quotes];
      updatedQuotes[quoteIndex] = {
        ...quoteToUpdate,
        isVisible: payload.visibility,
      };

      optimisiticUpdateOnMutate(queryKey, updatedQuotes);
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
  });
};
