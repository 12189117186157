import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import APAccordion from "../../../components/APAccordion";
import APAutoComplete, {
  AutoCompleteOption,
} from "../../../components/form/APAutoComplete";
import APCheckbox from "../../../components/form/APCheckbox";
import APFileUpload from "../../../components/form/APFileUpload";
import APTextField from "../../../components/form/APTextField";
import { PayFrequencyToPeriodsPerYear } from "../../../helpers/calculations/finance";
import { toDollars } from "../../../helpers/currencies";
import {
  calculateIncomeTotals,
  isRentIncomeRequired,
} from "../../../helpers/financeApplication";
import { PayFrequency } from "../../../types/Dossier";
import {
  employmentTypeOptions,
  FinanceApplicationInputs,
  payFrequencyOptions,
} from "..";

const FinanceApplicationIncomeForm = ({
  control,
  errors,
  watch,
  setValue,
  readOnly,
  highlightRequired,
  hideSpousalDeclaration,
  onDownloadSpousalIncomeDeclarationPDF,
  onTermsClick,
  onEditPropertyFields,
}: {
  control: Control<FinanceApplicationInputs>;
  errors: FieldErrors<FinanceApplicationInputs>;
  watch: UseFormWatch<FinanceApplicationInputs>;
  setValue: UseFormSetValue<FinanceApplicationInputs>;
  readOnly: boolean;
  highlightRequired: boolean;
  hideSpousalDeclaration: boolean;
  onDownloadSpousalIncomeDeclarationPDF: () => void;
  onTermsClick: () => void;
  onEditPropertyFields: (stepId: string) => void;
}) => {
  const watchSpouseIncome = watch("spouseIncludeIncome");
  const watchSpouseEmploymentType = watch("spouseEmploymentType");
  const showSpouseTaxReturns =
    !!watchSpouseEmploymentType && watchSpouseEmploymentType.id === "director";

  const watchSalaryPerPay = watch("spouseSalaryPerPay");
  const watchSalaryPayFrequency = watch("spousePayFrequency");

  const watchAnnualSalary = watch("annualSalaryTotal");
  const watchSpouseAnnualIncome = watch("spouseAnnualIncome");
  const watchInvestmentPropertyIncome = watch("investmentPropertyIncome");
  const watchInvestmentIncome = watch("investmentIncome");
  const watchGovtBenefitIncome = watch("govtBenefitIncome");
  const watchOtherIncome = watch("otherIncome");

  const watchResidenceStatus = watch("currentResidenceStatus");
  const watchRentPerPeriod = watch("rentAmount");
  const watchRentPayFrequency = watch("rentFrequency");
  const watchMortgagePerPeriod = watch("mortgageAmount");
  const watchMortgagePayFrequency = watch("mortgageFrequency");
  const watchVehiclePerPeriod = watch("vehicleAmount");
  const watchVehiclePayFrequency = watch("vehicleFrequency");
  const watchEducationPerPeriod = watch("educationAmount");
  const watchEducationPayFrequency = watch("educationFrequency");
  const watchGeneralExpensesPerPeriod = watch("generalExpensesAmount");
  const watchGeneralExpensesPayFrequency = watch("generalExpensesFrequency");
  const watchOtherExpensesPerPeriod = watch("otherExpensesAmount");
  const watchOtherExpensesPayFrequency = watch("otherExpensesFrequency");

  useEffect(() => {
    if (!watchSpouseIncome) {
      setValue("spouseAnnualIncome", "");
      setValue("spouseSalaryPerPay", "");
      setValue("spousePayFrequency", null);
      setValue("spouseNetMonthlyPay", "");
      setValue("spouseEmploymentType", null);
      setValue("spouseLastTaxReturnImage", null);
      setValue("spousePrevTaxReturnImage", null);
      setValue("spouseIncomeDeclarationImage", null);
      setValue("spouseLatestPayslipImage", null);
    }
  }, [watchSpouseIncome]);

  // If tax returns are no longer shown then clear the fields
  useEffect(() => {
    if (!showSpouseTaxReturns) {
      setValue("spouseLastTaxReturnImage", null);
      setValue("spousePrevTaxReturnImage", null);
    }
  }, [showSpouseTaxReturns]);

  const watchMontlyAmount = (
    perPay: string,
    frequency: AutoCompleteOption | null,
    monthlyPayField: keyof FinanceApplicationInputs
  ) => {
    useEffect(() => {
      if (perPay && frequency) {
        const monthlyAmount = Math.round(
          parseInt(perPay) *
            (PayFrequencyToPeriodsPerYear(frequency.id as PayFrequency) / 12)
        );
        setValue(monthlyPayField, `${monthlyAmount}`);
      } else {
        setValue(monthlyPayField, "");
      }
    }, [perPay, frequency]);

    return watch(monthlyPayField);
  };

  const watchSpouseNetMonthly = watchMontlyAmount(
    watchSalaryPerPay,
    watchSalaryPayFrequency,
    "spouseNetMonthlyPay"
  ) as string;
  const watchRentMonthly = watchMontlyAmount(
    watchRentPerPeriod,
    watchRentPayFrequency,
    "rentMonthly"
  ) as string;
  const watchMortgageMonthly = watchMontlyAmount(
    watchMortgagePerPeriod,
    watchMortgagePayFrequency,
    "mortgageMonthly"
  ) as string;
  const watchVehicleMonthly = watchMontlyAmount(
    watchVehiclePerPeriod,
    watchVehiclePayFrequency,
    "vehicleMonthly"
  ) as string;
  const watchEducationMonthly = watchMontlyAmount(
    watchEducationPerPeriod,
    watchEducationPayFrequency,
    "educationMonthly"
  ) as string;
  const watchGeneralExpensesMonthly = watchMontlyAmount(
    watchGeneralExpensesPerPeriod,
    watchGeneralExpensesPayFrequency,
    "generalExpensesMonthly"
  ) as string;
  const watchOtherExpensesMonthly = watchMontlyAmount(
    watchOtherExpensesPerPeriod,
    watchOtherExpensesPayFrequency,
    "otherExpensesMonthly"
  ) as string;

  const { totalIncome, totalExpenses, netIncome } = calculateIncomeTotals(
    watchAnnualSalary,
    watchSpouseAnnualIncome,
    watchInvestmentPropertyIncome,
    watchInvestmentIncome,
    watchGovtBenefitIncome,
    watchOtherIncome,
    watchRentMonthly,
    watchMortgageMonthly,
    watchVehicleMonthly,
    watchEducationMonthly,
    watchGeneralExpensesMonthly,
    watchOtherExpensesMonthly
  );

  return (
    <Stack spacing={2}>
      <APAccordion title="Other Income" defaultExpanded={true}>
        <Stack spacing={2} sx={{ pt: 2 }}>
          <APCheckbox
            name="spouseIncludeIncome"
            control={control}
            label="Include Spouse Income"
            defaultValue={false}
            readOnly={readOnly}
          />
          {watchSpouseIncome && (
            <Stack spacing={2}>
              <APTextField<FinanceApplicationInputs>
                name="spouseAnnualIncome"
                label="Spouse Annual Salary excl super"
                control={control}
                validations={{
                  formatValidation: /[0-9]+(\.[0-9][0-9])?/,
                }}
                errors={errors}
                defaultValue={""}
                startAdornment="$"
                placeholder="xxxxxxx.xx"
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
                formatNumber={true}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 2,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <APTextField<FinanceApplicationInputs>
                    name="spouseSalaryPerPay"
                    label="Spouse Salary After Tax Per Pay"
                    control={control}
                    validations={{
                      formatValidation: /[0-9]+(\.[0-9][0-9])?/,
                    }}
                    errors={errors}
                    defaultValue={""}
                    startAdornment="$"
                    placeholder="xxxxx.xx"
                    showRequired={true}
                    highlightOnEmpty={highlightRequired}
                    readOnly={readOnly}
                    formatNumber={true}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <APAutoComplete
                    name="spousePayFrequency"
                    label="Pay Frequency"
                    options={payFrequencyOptions}
                    control={control}
                    validations={{}}
                    errors={errors}
                    defaultValue={null}
                    showRequired={true}
                    highlightOnEmpty={highlightRequired}
                    readOnly={readOnly}
                  />
                </Box>
              </Box>

              {watchSpouseNetMonthly && (
                <Box>
                  <Typography variant="bodyLarge">
                    {toDollars(parseInt(watchSpouseNetMonthly), 0)} Spouse Net
                    Monthly Pay
                  </Typography>
                </Box>
              )}

              <APAutoComplete
                name="spouseEmploymentType"
                label="Spouse Employment Type"
                options={employmentTypeOptions}
                control={control}
                validations={{}}
                errors={errors}
                defaultValue={null}
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />

              {showSpouseTaxReturns && (
                <Stack spacing={2}>
                  <APFileUpload
                    name="spouseLastTaxReturnImage"
                    label="Spouse Last FY Tax Return"
                    control={control}
                    errors={errors}
                    showRequired={true}
                    highlightOnEmpty={highlightRequired}
                    readOnly={readOnly}
                  />

                  <APFileUpload
                    name="spousePrevTaxReturnImage"
                    label="Spouse Prev FY Tax Return"
                    control={control}
                    errors={errors}
                    showRequired={true}
                    highlightOnEmpty={highlightRequired}
                    readOnly={readOnly}
                  />
                </Stack>
              )}

              {!hideSpousalDeclaration && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <APFileUpload
                    name="spouseIncomeDeclarationImage"
                    label="Signed Spousal Income Declaration"
                    control={control}
                    errors={errors}
                    readOnly={readOnly}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => onDownloadSpousalIncomeDeclarationPDF()}
                  >
                    <DownloadIcon sx={{ mr: 0.5 }} />
                    <Typography variant="labelLarge">
                      Spousal Income Declaration Form
                    </Typography>
                  </Box>
                </Box>
              )}

              <APFileUpload
                name="spouseLatestPayslipImage"
                label="Spouse Latest Payslip"
                control={control}
                errors={errors}
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />
            </Stack>
          )}

          <APTextField<FinanceApplicationInputs>
            name="investmentPropertyIncome"
            label="Investment Property Income - Annual"
            control={control}
            validations={{
              formatValidation: /[0-9]+(\.[0-9][0-9])?/,
            }}
            errors={errors}
            defaultValue={""}
            startAdornment="$"
            placeholder="xxxxxxx.xx"
            readOnly={readOnly}
            formatNumber
            disabled
            endAdornment={
              <IconButton
                size="large"
                aria-label="edit-quote"
                color="primary"
                onClick={() => onEditPropertyFields("assetsAndLiabilities")}
              >
                <EditOutlinedIcon />
              </IconButton>
            }
          />

          <APTextField<FinanceApplicationInputs>
            name="investmentIncome"
            label="Investment Income - Annual"
            control={control}
            validations={{
              formatValidation: /[0-9]+(\.[0-9][0-9])?/,
            }}
            errors={errors}
            defaultValue={""}
            startAdornment="$"
            placeholder="xxxxxxx.xx"
            readOnly={readOnly}
            formatNumber={true}
          />

          <APTextField<FinanceApplicationInputs>
            name="govtBenefitIncome"
            label="Govt Benefit Income - Annual"
            control={control}
            validations={{
              formatValidation: /[0-9]+(\.[0-9][0-9])?/,
            }}
            errors={errors}
            defaultValue={""}
            startAdornment="$"
            placeholder="xxxxxxx.xx"
            readOnly={readOnly}
            formatNumber={true}
          />

          <APTextField<FinanceApplicationInputs>
            name="otherIncome"
            label="Other Income - Annual"
            control={control}
            validations={{
              formatValidation: /[0-9]+(\.[0-9][0-9])?/,
            }}
            errors={errors}
            defaultValue={""}
            startAdornment="$"
            placeholder="xxxxxxx.xx"
            readOnly={readOnly}
            formatNumber={true}
          />
        </Stack>
      </APAccordion>

      <APAccordion title="Expenses" defaultExpanded={true}>
        <Stack spacing={2} sx={{ pt: 2 }}>
          <Typography variant="bodySmall">
            Please enter the expense & frequency of expense.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <APTextField<FinanceApplicationInputs>
                name="rentAmount"
                label="Rent or Board/Period"
                control={control}
                validations={{
                  formatValidation: /[0-9]+(\.[0-9][0-9])?/,
                }}
                errors={errors}
                defaultValue={""}
                startAdornment="$"
                placeholder="xxxxx.xx"
                showRequired={isRentIncomeRequired(watchResidenceStatus)}
                highlightOnEmpty={
                  highlightRequired &&
                  isRentIncomeRequired(watchResidenceStatus)
                }
                readOnly={readOnly}
                formatNumber={true}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <APAutoComplete
                name="rentFrequency"
                label="Expense Frequency"
                options={payFrequencyOptions}
                control={control}
                validations={{}}
                errors={errors}
                defaultValue={null}
                showRequired={isRentIncomeRequired(watchResidenceStatus)}
                highlightOnEmpty={
                  highlightRequired &&
                  isRentIncomeRequired(watchResidenceStatus)
                }
                readOnly={readOnly}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <APTextField<FinanceApplicationInputs>
                name="mortgageAmount"
                label="Mortgage/Period"
                control={control}
                validations={{
                  formatValidation: /[0-9]+(\.[0-9][0-9])?/,
                }}
                errors={errors}
                defaultValue={""}
                startAdornment="$"
                placeholder="xxxxx.xx"
                readOnly={readOnly}
                formatNumber={true}
                disabled
                endAdornment={
                  <IconButton
                    size="large"
                    aria-label="edit-quote"
                    color="primary"
                    onClick={() => onEditPropertyFields("assetsAndLiabilities")}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                }
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <APAutoComplete
                name="mortgageFrequency"
                label="Expense Frequency"
                options={payFrequencyOptions}
                control={control}
                validations={{}}
                errors={errors}
                defaultValue={null}
                readOnly={readOnly}
                disabled
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <APTextField<FinanceApplicationInputs>
                name="vehicleAmount"
                label="Vehicle Running Cost/Period"
                control={control}
                validations={{
                  formatValidation: /[0-9]+(\.[0-9][0-9])?/,
                }}
                errors={errors}
                defaultValue={""}
                startAdornment="$"
                placeholder="xxxxx.xx"
                readOnly={readOnly}
                formatNumber={true}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <APAutoComplete
                name="vehicleFrequency"
                label="Expense Frequency"
                options={payFrequencyOptions}
                control={control}
                validations={{}}
                errors={errors}
                defaultValue={null}
                readOnly={readOnly}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <APTextField<FinanceApplicationInputs>
                name="educationAmount"
                label="Education or Childcare/Period"
                control={control}
                validations={{
                  formatValidation: /[0-9]+(\.[0-9][0-9])?/,
                }}
                errors={errors}
                defaultValue={""}
                startAdornment="$"
                placeholder="xxxxx.xx"
                readOnly={readOnly}
                formatNumber={true}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <APAutoComplete
                name="educationFrequency"
                label="Expense Frequency"
                options={payFrequencyOptions}
                control={control}
                validations={{}}
                errors={errors}
                defaultValue={null}
                readOnly={readOnly}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <APTextField<FinanceApplicationInputs>
                name="generalExpensesAmount"
                label="General Living Expense/Period"
                control={control}
                validations={{
                  formatValidation: /[0-9]+(\.[0-9][0-9])?/,
                }}
                errors={errors}
                defaultValue={""}
                startAdornment="$"
                placeholder="xxxxx.xx"
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
                formatNumber={true}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <APAutoComplete
                name="generalExpensesFrequency"
                label="Expense Frequency"
                options={payFrequencyOptions}
                control={control}
                validations={{}}
                errors={errors}
                defaultValue={null}
                showRequired={true}
                highlightOnEmpty={highlightRequired}
                readOnly={readOnly}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <APTextField<FinanceApplicationInputs>
                name="otherExpensesAmount"
                label="Other Expenses/Period"
                control={control}
                validations={{
                  formatValidation: /[0-9]+(\.[0-9][0-9])?/,
                }}
                errors={errors}
                defaultValue={""}
                startAdornment="$"
                placeholder="xxxxx.xx"
                readOnly={readOnly}
                formatNumber={true}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <APAutoComplete
                name="otherExpensesFrequency"
                label="Expense Frequency"
                options={payFrequencyOptions}
                control={control}
                validations={{}}
                errors={errors}
                defaultValue={null}
                readOnly={readOnly}
              />
            </Box>
          </Box>
        </Stack>
      </APAccordion>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          columnGap: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="bodyLarge">Total Monthly Income</Typography>
        </Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="bodyLarge">
            {toDollars(totalIncome, 0)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          columnGap: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="bodyLarge">Total Monthly Expenses</Typography>
        </Box>
        <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="bodyLarge">
            {toDollars(totalExpenses, 0)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "solid black 1px",
          borderBottom: "solid black 1px",
          columnGap: 2,
          py: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="bodyLarge">Net Monthly Income</Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            color: netIncome < 0 ? "error.main" : "initial",
          }}
        >
          <Typography variant="bodyLarge">{toDollars(netIncome, 0)}</Typography>
        </Box>
      </Box>

      <Box sx={{ py: 1 }}>
        <Button
          size="large"
          color="primary"
          variant="outlined"
          disableElevation={true}
          onClick={() => onTermsClick()}
        >
          Finance Terms
        </Button>
      </Box>

      <APCheckbox
        name="acceptTerms"
        control={control}
        label="I declare that this finance application is correct and complete and I agree to the Finance Terms"
        defaultValue={false}
        validations={{}}
        errors={errors}
        readOnly={readOnly}
        showRequired={true}
        highlightOnEmpty={highlightRequired}
      />
    </Stack>
  );
};

export default FinanceApplicationIncomeForm;
