import { useQuery } from "react-query";

import { KeycloakUserInfo } from "../../types/User";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getKeycloakUserInfo = (
  contactId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["keycloakUserInfo", contactId],
    queryFn: (): Promise<KeycloakUserInfo> => {
      return fetchWithAuth<KeycloakUserInfo>("getKeycloakUserInfo", contactId);
    },
    enabled: isQueryEnabled(options),
  });
};
