import "./App.css";

import Box from "@mui/material/Box";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { buildApiDirectory } from "./api/ApiDirectory";
import { ApiProvider } from "./api/ApiProvider";
import { APQueryProvider } from "./api/APQueryProvider";
import { AuthProvider, useAuth } from "./api/AuthProvider";
import {
  buildPageConfigurations,
  buildPageDirectory,
} from "./api/PageDirectory";
import { getMyUser } from "./api/user/getMyUser";
import APModalStack from "./components/APModalStack";
import CrmLinkRouter from "./components/CrmLinkRouter";
import InitialSetupRoute from "./components/InitialSetupRoute";
import LoadingBackdrop from "./components/LoadingBackdrop";
import NavBar from "./components/NavBar";
import PageViewTracker from "./components/PageViewTracker";
import ProtectedRoute from "./components/ProtectedRoute";
import RestrictedAppScope from "./components/RestrictedAppScope";
import ScrollToTop from "./components/ScrollToTop";
import ShowMFAPrompt from "./components/ShowMFAPrompt";
import { Config } from "./config";
import ViewKeycloakUser from "./contact/viewKeycloakUser";
import ActionConfirmation from "./dossier/actionConfirmation";
import DirectDebit from "./dossier/directDebit";
import DossierList from "./dossier/dossierList";
import EmployerSetup from "./dossier/employerSetup";
import FinanceApplication from "./dossier/financeApplication";
import PackagingAgreement from "./dossier/packagingAgreement";
import ViewDossier from "./dossier/viewDossier";
import RedirectOpportunityToDossier from "./dossier/viewDossier/RedirectOpportunityToDossier";
import ApproveDrivers from "./employer/approveDrivers";
import DocumentList from "./employer/documentList";
import EmployerHome from "./employer/home";
import ViewFaq from "./faq/viewFaq";
import ViewAppLocationFaqs from "./faq/viewFaqList";
import Home from "./home";
import CreateQuote, { QuoteCreateAction } from "./quote/createQuote";
import PersonaliseQuote from "./quote/personaliseQuote";
import ViewQuote from "./quote/viewQuote";
import ViewSurvey from "./survey/viewSurvey";
import UserInfo from "./userInfo";
import EnableMFA from "./userInfo/enableMFA";

function App() {
  const { keycloak } = useKeycloak();
  const { inAppScope, isAuthorised, getScopedPageUrl } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [modalStack, setModalStack] = useState([] as string[]);
  const modalPath = searchParams.get("modal");
  const employerId = searchParams.get("EmployerID") || undefined;

  if (modalPath) {
    // If navigating to previous modal,then pop modal stack
    if (location.state?.popModalStack) {
      location.state.popModalStack = null;
      const newStack = modalStack.slice(0, modalStack.length - 1);

      setModalStack(newStack);
    } else {
      // Else we add to stack if it is a new modal path
      const lastModalPath =
        (modalStack.length > 0 && modalStack[modalStack.length - 1]) || null;

      if (!lastModalPath || lastModalPath !== modalPath) {
        setModalStack([...modalStack, modalPath]);
      }
    }
  } else if (modalStack.length > 0) {
    setModalStack([]);
  }

  const {
    data: me,
    isLoading,
    isSuccess,
    isError,
    error,
  } = getMyUser(employerId);

  if (isError) {
    throw error;
  }

  return (
    <>
      {isSuccess && keycloak.token && (
        <React.Fragment>
          <RestrictedAppScope scope="external">
            <NavBar me={me} />
            <ShowMFAPrompt mfaEnabled={me.shouldPromptMFA} />
          </RestrictedAppScope>
          <RestrictedAppScope scope="internal">
            <Box m={2} />
          </RestrictedAppScope>
          <PageViewTracker />
          <ScrollToTop />
          {inAppScope("internal") && isAuthorised("internal", me) && (
            <Routes>
              <Route
                path={getScopedPageUrl("home")}
                element={<div>Internal Home</div>}
              />
              <Route
                path={getScopedPageUrl("crmLinkRouter", ":destinationId")}
                element={<CrmLinkRouter />}
              />
              <Route
                path={getScopedPageUrl("viewDossier", ":dossierId")}
                element={<ViewDossier />}
              />
              <Route
                path={getScopedPageUrl("viewOpportunity", ":opportunityId")}
                element={<RedirectOpportunityToDossier />}
              />
              <Route
                path={getScopedPageUrl("createQuote", ":dossierId")}
                element={
                  <CreateQuote me={me} action={QuoteCreateAction.Create} />
                }
              />
              <Route
                path={getScopedPageUrl("viewQuote", ":quoteId")}
                element={<ViewQuote />}
              />
              <Route
                path={getScopedPageUrl("personaliseQuote", ":quoteId")}
                element={<PersonaliseQuote />}
              />
              <Route
                path={getScopedPageUrl("editQuote", ":quoteId")}
                element={
                  <CreateQuote me={me} action={QuoteCreateAction.Edit} />
                }
              />
              <Route
                path={getScopedPageUrl("cloneQuote", ":quoteId")}
                element={
                  <CreateQuote me={me} action={QuoteCreateAction.Clone} />
                }
              />
              <Route
                path={getScopedPageUrl("viewKeycloakUser", ":contactId")}
                element={<ViewKeycloakUser />}
              />
              <Route
                path="*"
                element={<Navigate to={getScopedPageUrl("home")} />}
              />
            </Routes>
          )}
          {inAppScope("external") && isAuthorised("external", me) && (
            <Routes>
              <Route
                path={getScopedPageUrl("viewDossiers")}
                element={
                  <ProtectedRoute me={me}>
                    <DossierList />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("viewDossier", ":dossierId")}
                element={
                  <ProtectedRoute me={me}>
                    <ViewDossier />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("createQuote", ":dossierId")}
                element={
                  <ProtectedRoute me={me}>
                    <CreateQuote me={me} action={QuoteCreateAction.Create} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("dossierEmployerSetup", ":dossierId")}
                element={
                  <ProtectedRoute me={me}>
                    <EmployerSetup />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl(
                  "dossierFinanceApplication",
                  ":dossierId"
                )}
                element={
                  <ProtectedRoute me={me}>
                    <FinanceApplication />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl(
                  "dossierPackagingAgreement",
                  ":dossierId"
                )}
                element={
                  <ProtectedRoute me={me}>
                    <PackagingAgreement />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("dossierDirectDebit", ":dossierId")}
                element={
                  <ProtectedRoute me={me}>
                    <DirectDebit />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl(
                  "dossierActionConfirmation",
                  ":dossierId",
                  ":eventId"
                )}
                element={
                  <ProtectedRoute me={me}>
                    <ActionConfirmation />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("employerHome")}
                element={
                  <ProtectedRoute me={me}>
                    <EmployerHome me={me} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("employerApproveDrivers")}
                element={
                  <ProtectedRoute me={me}>
                    <ApproveDrivers me={me} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("employerInvoices")}
                element={
                  <ProtectedRoute me={me}>
                    <DocumentList me={me} page={"employerInvoices"} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("employerPackagingAgreements")}
                element={
                  <ProtectedRoute me={me}>
                    <DocumentList
                      me={me}
                      page={"employerPackagingAgreements"}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("employerPayrollAdvice")}
                element={
                  <ProtectedRoute me={me}>
                    <DocumentList me={me} page={"employerPayrollAdvice"} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("employerFBTReports")}
                element={
                  <ProtectedRoute me={me}>
                    <DocumentList me={me} page={"employerFBTReports"} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("employerEngagementReports")}
                element={
                  <ProtectedRoute me={me}>
                    <DocumentList me={me} page={"employerEngagementReports"} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("employerPromotions")}
                element={
                  <ProtectedRoute me={me}>
                    <DocumentList me={me} page={"employerPromotions"} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("employerLuxuryLeaseReports")}
                element={
                  <ProtectedRoute me={me}>
                    <DocumentList me={me} page={"employerLuxuryLeaseReports"} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("viewQuote", ":quoteId")}
                element={
                  <ProtectedRoute me={me}>
                    <ViewQuote />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("personaliseQuote", ":quoteId")}
                element={
                  <ProtectedRoute me={me}>
                    <PersonaliseQuote />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("editQuote", ":quoteId")}
                element={
                  <ProtectedRoute me={me}>
                    <CreateQuote me={me} action={QuoteCreateAction.Edit} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("cloneQuote", ":quoteId")}
                element={
                  <ProtectedRoute me={me}>
                    <CreateQuote me={me} action={QuoteCreateAction.Clone} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("viewSurvey", ":surveyId")}
                element={
                  <ProtectedRoute me={me}>
                    <ViewSurvey />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("initialUser")}
                element={
                  <InitialSetupRoute me={me}>
                    <UserInfo />
                  </InitialSetupRoute>
                }
              />
              <Route
                path={getScopedPageUrl("userDirectDebit")}
                element={
                  <ProtectedRoute me={me}>
                    <DirectDebit />
                  </ProtectedRoute>
                }
              />
              <Route
                path={getScopedPageUrl("home")}
                element={
                  <ProtectedRoute me={me}>
                    <Home me={me} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="*"
                element={
                  <Navigate
                    to={`${getScopedPageUrl("home")}${location.search}`}
                  />
                }
              />
            </Routes>
          )}
          {modalStack.length > 0 && (
            <Routes
              location={{
                ...location,
                pathname: modalStack[modalStack.length - 1],
              }}
            >
              <Route
                element={
                  <APModalStack
                    modalStack={modalStack}
                    appLocation={location}
                    size="medium"
                  />
                }
              >
                <Route
                  path={getScopedPageUrl("modalFaq", ":faqId")}
                  element={<ViewFaq />}
                />
                <Route
                  path={getScopedPageUrl("modalAppLocation", ":appLocationId")}
                  element={<ViewAppLocationFaqs />}
                />

                <Route
                  path={getScopedPageUrl(
                    "modalAppLocationFaq",
                    ":appLocationId",
                    ":faqId"
                  )}
                  element={<ViewAppLocationFaqs />}
                />
              </Route>
              <Route
                element={
                  <APModalStack
                    modalStack={modalStack}
                    appLocation={location}
                    size="small"
                    hideClose
                    disableBackdropClose
                  />
                }
              >
                {inAppScope("external") && (
                  <Route
                    path={getScopedPageUrl("modalEnableMFA")}
                    element={<EnableMFA me={me} appLocation={location} />}
                  />
                )}
              </Route>
            </Routes>
          )}
        </React.Fragment>
      )}
      {isLoading && <LoadingBackdrop />}
    </>
  );
}

function ApiWrappedApp() {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    const handleFocus = () => {
      keycloak.updateToken(0);
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  return (
    <>
      {keycloak.token && (
        <ApiProvider
          token={keycloak.token}
          baseUrl={Config.apiUrl}
          apiDirectory={buildApiDirectory(Config.applicationScope)}
        >
          <AuthProvider
            pageDirectory={buildPageDirectory(Config.applicationScope)}
            pageConfigurations={buildPageConfigurations(
              Config.applicationScope
            )}
          >
            <APQueryProvider>
              <App />
            </APQueryProvider>
          </AuthProvider>
        </ApiProvider>
      )}
    </>
  );
}

export default ApiWrappedApp;
