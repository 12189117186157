export const getNestedProperty = (
  propertyPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>
) => {
  const keys = propertyPath.split(".");
  for (const key of keys) {
    if (!object || typeof object !== "object" || !(key in object)) {
      return undefined;
    }
    object = object[key];
  }
  return object;
};
