import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const APDetailRow = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="titleMedium">{label}</Typography>
      <Typography variant="titleMedium">{value}</Typography>
    </Box>
  );
};

export default APDetailRow;
