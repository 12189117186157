import VisibilityOutlinedOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { SxProps, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";

const VisibilityButton = ({
  isVisible,
  onToggle,
  sx,
}: {
  isVisible: boolean;
  onToggle: (newVisibility: boolean) => void;
  sx?: SxProps<Theme>;
}) => {
  return (
    <IconButton
      size="large"
      aria-label="quote-visibility"
      color="onSurface"
      onClick={() => onToggle(!isVisible)}
      sx={{ ...sx, opacity: isVisible ? "100%" : "40%" }}
    >
      {isVisible ? <VisibilityOutlinedIcon /> : <VisibilityOutlinedOffIcon />}
    </IconButton>
  );
};

export default VisibilityButton;
