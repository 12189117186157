import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";

import { getNestedProperty } from "../../helpers/object";
import {
  ValidationOptions,
  validationOptionsToControlRules,
} from "../../types/Validations";

export type RadioGroupOption = {
  label: string;
  id: string;
};

interface APRadioGroupProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  options: RadioGroupOption[];
  validations: ValidationOptions;
  errors: FieldErrors<T>;
  control: Control<T>;
  defaultValue: PathValue<T, Path<T>> | undefined;
  showRequired?: boolean;
  highlightOnEmpty?: boolean;
  readOnly?: boolean;
}

const APRadioGroup = <T extends FieldValues>({
  name,
  label,
  options,
  errors,
  validations,
  control,
  defaultValue,
  showRequired,
  readOnly,
  highlightOnEmpty,
}: APRadioGroupProps<T>) => {
  const error = getNestedProperty(name, errors);
  const renderedOptions = options.map((option) => {
    return (
      <FormControlLabel
        key={option.id}
        value={option.id}
        control={<Radio />}
        label={option.label}
        disabled={readOnly}
      />
    );
  });

  return (
    <FormControl error={!!error}>
      <Controller<T>
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={validationOptionsToControlRules(label, validations)}
        render={({ field }) => (
          <Box>
            <Typography
              variant="titleMedium"
              color={
                !!error || (highlightOnEmpty && !field.value)
                  ? "error.main"
                  : "initial"
              }
            >
              {validations.required || showRequired ? `${label} *` : label}
            </Typography>
            <RadioGroup sx={{ ml: 2, mt: 1 }} {...field}>
              {renderedOptions}
            </RadioGroup>
          </Box>
        )}
      />
      {!!error && <FormHelperText>{`${error?.message}`}</FormHelperText>}
    </FormControl>
  );
};

export default APRadioGroup;
