import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { createElement } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from "survey-react-ui";

const CUSTOM_TYPE = "boolean-thumbs";

export class BooleanThumbsQuestionModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

export class SurveyBooleanThumbs extends SurveyQuestionElementBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = { value: this.question.value };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }

  handleBooleanChange = (change: boolean | null) => {
    this.question.value = change;
  };

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue("readOnly") ||
      this.question.isDesignMode
      ? { pointerEvents: "none" }
      : undefined;
  }

  renderElement() {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <div style={this.style as any}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            sx={{ color: "tertiaryGreen.main" }}
            onClick={() =>
              this.handleBooleanChange(this.value === true ? null : true)
            }
          >
            {this.value === true ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
          </IconButton>

          <IconButton
            color="error"
            onClick={() =>
              this.handleBooleanChange(this.value === false ? null : false)
            }
          >
            {this.value === false ? (
              <ThumbDownIcon />
            ) : (
              <ThumbDownOutlinedIcon />
            )}
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 0.5,
            height: 24,
          }}
        >
          {(this.value === true || this.value === false) && (
            <Typography variant="bodyLarge" color="primary.main">
              {this.value ? "Yes" : "No"}
            </Typography>
          )}
        </Box>
      </div>
    );
  }
}

export function registerBooleanThumbs() {
  Serializer.addClass(
    CUSTOM_TYPE,
    [],
    function () {
      return new BooleanThumbsQuestionModel("");
    },
    "question"
  );

  ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    return new BooleanThumbsQuestionModel(name);
  });

  ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return createElement(SurveyBooleanThumbs, props);
  });
}
