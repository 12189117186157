import { useQuery } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getDossierIdFromOpportunityId = (
  opportunityId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["dossierId", "fromOpportunityId", opportunityId],
    queryFn: (): Promise<string> => {
      return fetchWithAuth<string>("getDossierByOpportunityId", opportunityId);
    },
    enabled: isQueryEnabled(options),
  });
};
