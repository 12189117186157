export interface APEvent {
  event: string;
  data: Record<string, unknown>;
}
export class PageViewEvent implements APEvent {
  event = "pageView";
  data: Record<string, unknown>;

  constructor(path: string) {
    this.data = {
      path,
    };
  }
}
