import { useQuery,  } from 'react-query';

import { Contact } from '../../types/Contact';
import { useApi } from '../ApiProvider';

export const getMyContact = () => {  
  const { fetchWithAuth } = useApi();
  return useQuery(["contact"], (): Promise<Contact> => {
    return fetchWithAuth<Contact>("getMyContact");
  });
};