import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { BallTriangle } from "react-loading-icons";
import TextTransition, { presets } from "react-text-transition";
import uniqueRandom from "unique-random";

const loadingTexts = [
  "crunching fees",
  "loading tax deductions",
  "saving GST",
  "increasing disposable income",
  "decreasing income tax",
  "maximising savings",
  "manifesting goodness",
  "uploading new car models",
  "sourcing great deals",
  "deconstructing status quo",
  "recalibrating UX",
  "transcending paradigms",
  "novated shape shifting",
  "unlocking the vault",
  "uploading more good",
];

const LoadingBackdrop = () => {
  const random = uniqueRandom(0, loadingTexts.length - 1);
  const [index, setIndex] = useState(random());
  const [usedIndexes, setUsedIndexes] = useState([] as number[]);

  // Generate random index that has not been used yet
  const getRandomIndex = (): number => {
    const index = random();

    return usedIndexes.indexOf(index) === -1 ? index : getRandomIndex();
  };

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        const index = getRandomIndex();
        if (usedIndexes.length === loadingTexts.length - 1) {
          usedIndexes.splice(0);
        } else {
          usedIndexes.push(index);
        }

        setUsedIndexes(usedIndexes);
        setIndex(index);
      },
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  // NOTE: zIndex of 1399 is used for the loading backdrop so that it is lower then
  // the zIndex of the notistack snackbar which is 1400
  return (
    <Backdrop open={true} sx={{ background: "#ffffffde", zIndex: "1399" }}>
      <Stack spacing={2} alignItems="center">
        <BallTriangle
          stroke="#EB008B"
          gradientTransform="radial"
          stopColor="red"
          lightingColor="blue"
        />
        <Typography variant="labelLarge">
          <TextTransition springConfig={presets.default} direction="down">
            {loadingTexts[index]}
          </TextTransition>
        </Typography>
      </Stack>
    </Backdrop>
  );
};

export default LoadingBackdrop;
