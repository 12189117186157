import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from "react-hook-form";

import { fileUploadTypePattern } from "../../helpers/files";
import { getNestedProperty } from "../../helpers/object";

interface APFileUploadProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  errors: FieldErrors<T>;
  helperText?: string;
  showRequired?: boolean;
  highlightOnEmpty?: boolean;
  readOnly?: boolean;
}

const APFileUpload = <T extends FieldValues>({
  name,
  label,
  control,
  errors,
  helperText,
  showRequired,
  highlightOnEmpty,
  readOnly,
}: APFileUploadProps<T>) => {
  const error = getNestedProperty(name, errors);

  return (
    <Controller<T>
      name={name}
      control={control}
      render={({ field }) => (
        <MuiFileInput
          {...field}
          label={showRequired ? `${label} *` : label}
          value={field.value}
          error={!!error || (highlightOnEmpty && !field.value)}
          onChange={(file) => {
            if (file) {
              if (fileUploadTypePattern.test(file.type)) {
                field.onChange(file);
              } else {
                alert("Invalid file type");
              }
            }
          }}
          disabled={readOnly}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ position: "absolute", right: "14px" }}
              >
                <AttachFileIcon />
              </InputAdornment>
            ),
            readOnly: readOnly,
          }}
          helperText={`${error?.message || helperText || ""}`}
        />
      )}
    />
  );
};

export default APFileUpload;
