import { useQuery } from "react-query";

import { Quote } from "../../types/Quote";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getQuotes = (dossierId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["quotes", dossierId],
    queryFn: (): Promise<Quote[]> => {
      return fetchWithAuth<Quote[]>("getDossierQuotes", dossierId);
    },
    enabled: isQueryEnabled(options),
  });
};
