import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type CheckListItem = {
  label: string;
  checked: boolean;
};

const APCheckList = ({
  items,
  hideIconOnUnchecked = false,
}: {
  items: CheckListItem[];
  hideIconOnUnchecked?: boolean;
}) => {
  return (
    <Box>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{ display: "flex", alignItems: "center", columnGap: 0.5 }}
        >
          <Box sx={{ display: "flex", width: 24, height: 24 }}>
            {item.checked && <CheckIcon sx={{ color: "tertiaryGreen.main" }} />}
            {!item.checked && !hideIconOnUnchecked && (
              <CloseIcon sx={{ color: "error.main" }} />
            )}
          </Box>
          <Typography variant="bodySmall">{item.label}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default APCheckList;
