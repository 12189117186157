import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactChild, useState } from "react";
import { Carousel } from "react-responsive-carousel";

import APSpeedoLoader from "./APSpeedoLoader";

const APCarousel = ({ children }: { children: ReactChild[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSpeedoStart, setCurrentSpeedoStart] = useState(Date.now());

  const handleChange = (index: number) => {
    setCurrentIndex(index);
    setCurrentSpeedoStart(Date.now());
  };

  const changeIndex = () => {
    setCurrentIndex((prev) => prev + 1);
  };

  return (
    <Box>
      <Carousel
        selectedItem={currentIndex}
        onChange={handleChange}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        infiniteLoop
        interval={6000}
      >
        {children}
      </Carousel>
      {children.length > 1 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            mx: 4,
            mt: 1,
          }}
        >
          <Box>
            <IconButton
              color="inherit"
              size="small"
              sx={{ visibility: "visible" }}
              onClick={() => handleChange(currentIndex - 1)}
            >
              <ArrowLeftIcon />
            </IconButton>
          </Box>
          <Box sx={{ mx: 0.5 }}>
            <APSpeedoLoader
              handleChange={changeIndex}
              startTime={currentSpeedoStart}
              duration={6000}
            />
          </Box>
          <Box>
            <IconButton
              color="inherit"
              size="small"
              sx={{ visibility: "visible" }}
              onClick={() => handleChange(currentIndex + 1)}
            >
              <ArrowRightIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default APCarousel;
