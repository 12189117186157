import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const NEW_LEAD_DELAY = 10000;
export const NEW_LEAD_DELAY_ALERT =
  "Working on the car of your dreams, Almost there.";
export const NEW_LEAD_TIMEOUT = 30000;
export const NEW_LEAD_TIMEOUT_ALERT = "Error: lead qualification took too long";

type QualifyLeadPayload = {
  mobilePhone: string;
  companyName: string;
  leadSourceCode?: string;
};

export const qualifyLead = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
    alertOnLongRequest,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: QualifyLeadPayload): Promise<string> => {
      return alertOnLongRequest(
        fetchWithAuth<string>("qualifyLead", undefined, {
          requestOptions: { method: "post", body: JSON.stringify(payload) },
        }),
        NEW_LEAD_DELAY,
        NEW_LEAD_DELAY_ALERT,
        NEW_LEAD_TIMEOUT,
        NEW_LEAD_TIMEOUT_ALERT
      );
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () => defaultOnMutateSettled([["user"]]),
  });
};
