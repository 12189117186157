import { AutoCompleteOption } from "../components/form/APAutoComplete";

export type State = "NSW" | "QLD" | "VIC" | "NT" | "WA" | "SA" | "TAS" | "ACT";

export const stateOptions: AutoCompleteOption[] = [
  { label: "NSW", id: "NSW" },
  { label: "VIC", id: "VIC" },
  { label: "QLD", id: "QLD" },
  { label: "SA", id: "SA" },
  { label: "NT", id: "NT" },
  { label: "WA", id: "WA" },
  { label: "ACT", id: "ACT" },
  { label: "TAS", id: "TAS" },
];
