import { useQuery } from "react-query";

import { VehicleOptions } from "../../types/Vehicles";
import { useApi } from "../ApiProvider";
import {
  APQueryOptions,
  LONG_STALE_TIME,
  useAPQuery,
} from "../APQueryProvider";

export const getVehicleOptions = (
  variantId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["vehicle-options", variantId],
    queryFn: (): Promise<VehicleOptions> => {
      return fetchWithAuth<VehicleOptions>("getVehicleOptions", variantId);
    },
    enabled: isQueryEnabled(options),
    staleTime: LONG_STALE_TIME,
  });
};
