import { useQuery } from "react-query";

import { Faq } from "../../types/Faq";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getFaqs = (
  appLocationId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["faqs", appLocationId],
    queryFn: (): Promise<Faq[]> => {
      return fetchWithAuth<Faq[]>("getFaqs", appLocationId);
    },
    enabled: isQueryEnabled(options),
  });
};
