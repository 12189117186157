import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type SignPackagingAgreementPayload = {
  driverDevice: string;
};

export const signPackagingAgreement = (
  dossierId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();
  const invalidateList = [
    ["dossier", dossierId],
    ["dossierStatus", dossierId],
  ];

  return useMutation({
    mutationFn: (payload: SignPackagingAgreementPayload): Promise<string> => {
      return fetchWithAuth<string>("signPackagingAgreement", dossierId, {
        requestOptions: { method: "post", body: JSON.stringify(payload) },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () => defaultOnMutateSettled(invalidateList),
  });
};
