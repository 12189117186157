import { useMutation } from "react-query";

import { DossierQuoteSyncInitResult } from "../../types/Quote";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type SyncQuotesPayload = {
  contactId: string;
};

export const syncQuotes = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { defaultOnMutateError, defaultOnMutateSettled } = useAPQuery();

  return useMutation({
    mutationFn: (
      payload: SyncQuotesPayload
    ): Promise<DossierQuoteSyncInitResult> => {
      return fetchWithAuth<DossierQuoteSyncInitResult>(
        "syncQuotes",
        payload.contactId,
        {
          requestOptions: { method: "post" },
        }
      );
    },
    onError: defaultOnMutateError(),
    onSuccess: (result: DossierQuoteSyncInitResult, payload) => {
      let errorMessage: string | undefined;

      switch (result) {
        case "NO_ACTIVE_DOSSIER":
          errorMessage = "This contact has no active dossier.";
          break;
        case "CATCHE_DRIVER_NOT_LINKED":
          errorMessage = "This contact is not linked to a Catch-e driver";
          break;
      }

      const onError = defaultOnMutateError({
        ...options,
        mutationOptions: {
          ...options.mutationOptions,
          errorAlertMessage: errorMessage,
        },
      });

      if (result === "SYNCING") {
        defaultOnMutateSettled([["syncQuotesStatus", payload.contactId]]);
      } else {
        onError();
      }
    },
  });
};
