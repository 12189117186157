import { useQuery } from "react-query";

import { VehicleVariant } from "../../types/Vehicles";
import { useApi } from "../ApiProvider";
import {
  APQueryOptions,
  LONG_STALE_TIME,
  useAPQuery,
} from "../APQueryProvider";

export const getVehicleVariants = (
  makeId: string | null,
  modelId: string | null,
  modelYear: string | null,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["vehicle-variants", makeId, modelId, modelYear],
    queryFn: (): Promise<VehicleVariant[]> => {
      if (makeId && modelId && modelYear) {
        return fetchWithAuth<VehicleVariant[]>(
          "getVehicleVariants",
          undefined,
          {
            queryParams: { makeId, modelId, modelYear },
          }
        );
      } else {
        return Promise.resolve([]);
      }
    },
    enabled: isQueryEnabled(options),
    staleTime: LONG_STALE_TIME,
  });
};
