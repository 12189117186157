import { useKeycloak } from "@react-keycloak/web";
import React, { createContext, ReactNode, useContext } from "react";

import { Config } from "../config";
import { hasAccountRoles } from "../helpers/user";
import { ApplicationScope, AuthScope, KeycloakRole } from "../types/App";
import { User } from "../types/User";
import {
  PageConfigName,
  PageConfigurations,
  PageDirectory,
  PageDirectoryEntryName,
} from "./PageDirectory";

// Define the context with a fetch function
const AuthContext = createContext<{
  inAppScope: (scope: ApplicationScope) => boolean;
  isAuthorised: (scope: AuthScope, user: User) => boolean;
  getScopedPageUrl: (
    entry: PageDirectoryEntryName,
    entityId?: string,
    secondaryId?: string
  ) => string;
  getScopedPageConfig: <T>(configName: PageConfigName) => T;
}>({
  inAppScope: () => false,
  isAuthorised: () => false,
  getScopedPageUrl: () => "",
  getScopedPageConfig: <T,>() => "" as T,
});

// Custom hook to use the Auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

// Auth Provider component
export const AuthProvider: React.FC<{
  pageDirectory: PageDirectory;
  pageConfigurations: PageConfigurations;
  children: ReactNode;
}> = ({ pageDirectory, pageConfigurations, children }) => {
  const { keycloak } = useKeycloak();

  const inAppScope = (scope: ApplicationScope) => {
    return scope === (Config.applicationScope || "external");
  };

  const isAuthorised = (authScope: AuthScope, user: User) => {
    const roles =
      (keycloak.tokenParsed?.resource_access?.["auto-pilot-portal"]
        ?.roles as KeycloakRole[]) || [];
    let isAuthorised = false;

    switch (authScope) {
      case "external":
        isAuthorised = true;
        break;
      case "external-employer":
        isAuthorised =
          !!user.contactRoles && hasAccountRoles(user.contactRoles);
        break;
      case "internal":
        isAuthorised = roles.includes("internal-portal-user");
        break;
    }

    return isAuthorised;
  };

  const getScopedPageUrl = (
    directoryEntry: PageDirectoryEntryName,
    entityId?: string,
    secondaryId?: string
  ): string => {
    const entry = pageDirectory[directoryEntry];
    if (entry) {
      return entry(entityId, secondaryId);
    } else {
      // If no entry is found then redirect to home
      return pageDirectory.home?.() || "";
    }
  };

  const getScopedPageConfig = <T,>(configName: PageConfigName): T => {
    const config = pageConfigurations[configName];

    if (config) {
      return pageConfigurations[configName] as T;
    } else {
      throw Error(`No configuration found, entry: ${configName}`);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        inAppScope,
        isAuthorised,
        getScopedPageUrl,
        getScopedPageConfig,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
