import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import APAccordion from "../../components/APAccordion";

export type TermId = "quote" | "finance" | "vehicleOption";

const Terms = ({ termsId }: { termsId: TermId }) => {
  let pageTitle = "";
  let termsText = "";

  switch (termsId as TermId) {
    case "finance":
      pageTitle = "Finance Terms";
      termsText =
        "I have read and understood the particulars that have been completed in this declaration and state that those particulars are true, complete and not misleading, and will be supplied to the Financier to enable it to assess my finance application.";
      break;
    case "quote":
      pageTitle = "Terms";
      termsText =
        "^Indicative cost and savings only.<br/><br/>Actual savings may vary depending on Your unique circumstances. You should obtain Your own independent legal, financial and taxation advice in relation to this quote and should not rely on any information supplied to You by Auto-UX in relation to the adequacy or appropriateness of these arrangements for You.";
      break;
    case "vehicleOption":
      pageTitle = "Terms";
      termsText =
        "^Estimated net cost after income tax and GST savings of salary sacrifice arrangement. Actual cost after salary sacrifice may differ. See the Inc-GST Cost for the gross cost before estimated effect of salary sacrifice. You should obtain Your own independent legal, financial and taxation advice in relation to this quote and should not rely on any information supplied to You by Auto-UX in relation to the adequacy or appropriateness of these arrangements for You.";
      break;
    default:
      break;
  }

  return (
    <Stack spacing={2}>
      <APAccordion title={pageTitle} alwaysExpanded={true} hideExpand={true}>
        <Typography variant="titleMedium">
          <div dangerouslySetInnerHTML={{ __html: termsText }} />
        </Typography>
      </APAccordion>
    </Stack>
  );
};

export default Terms;
