import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../api/AuthProvider";
import { hasAccountRoles } from "../helpers/user";
import { User } from "../types/User";

const Home = ({ me }: { me: User }) => {
  const navigate = useNavigate();
  const { getScopedPageUrl } = useAuth();

  useEffect(() => {
    if (me.contactRoles && hasAccountRoles(me.contactRoles)) {
      navigate(getScopedPageUrl("employerHome"), {
        replace: true,
      });
    } else {
      navigate(getScopedPageUrl("viewDossiers"), {
        replace: true,
      });
    }
  });

  return <div></div>;
};

export default Home;
