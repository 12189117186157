import { useQuery } from "react-query";

import { BankAccount } from "../../types/Contact";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getMyBankAccount = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["bankAccount"],
    queryFn: (): Promise<BankAccount | null> => {
      return fetchWithAuth<BankAccount | null>("getMyBankAccount");
    },
    enabled: isQueryEnabled(options),
  });
};
