import { Navigate, useParams } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import { getDossierIdFromOpportunityId } from "../../api/dossier/getDossierIdFromOpportunityId";
import PageContainer from "../../components/PageContainer";

const RedirectOpportunityToDossier = () => {
  const { opportunityId } = useParams();

  const { getScopedPageUrl } = useAuth();

  if (!opportunityId) {
    // TODO: improve client side error messaging
    throw new Error("opportunity id param is missing");
  }

  const dossierId = getDossierIdFromOpportunityId(opportunityId);

  if (dossierId.isError) {
    throw dossierId.error;
  }

  return (
    <PageContainer loading={dossierId.isLoading}>
      {dossierId.isSuccess && (
        <Navigate to={getScopedPageUrl("viewDossier", dossierId.data)} />
      )}
    </PageContainer>
  );
};

export default RedirectOpportunityToDossier;
