import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import Terms, { TermId } from "../terms/components/Terms";

const TermsButton = ({ termsId }: { termsId: TermId }) => {
  const [showTermsModal, setShowTermsModal] = useState(false);

  const onTermsClick = () => {
    setShowTermsModal(true);
  };

  const onCloseTermsModal = () => {
    setShowTermsModal(false);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Typography
          sx={{ cursor: "pointer" }}
          variant="labelSmall"
          onClick={() => onTermsClick()}
        >
          ^ Terms
        </Typography>
      </Box>
      <Modal open={showTermsModal} onClose={onCloseTermsModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Paper
            sx={{
              py: 2,
              px: 4,
              overflowY: "auto",
              maxWidth: "900px",
              maxHeight: "95vh",
              width: "95vw",
            }}
          >
            <Terms termsId={termsId} />
            <Box sx={{ mt: 3, display: "flex", justifyContent: "end" }}>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                disableElevation={true}
                onClick={onCloseTermsModal}
              >
                Close
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

export default TermsButton;
