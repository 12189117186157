import { useEffect, useState } from "react";

import { getSyncQuotesStatus } from "../../api/user/getSyncQuotesStatus";
import { syncQuotes } from "../../api/user/syncQuotes";
import CatchELoadingButton from "./CatchELoadingButton";

const SyncQuoteButton = ({
  contactId,
  quoteId,
}: {
  contactId: string;
  quoteId?: string;
}) => {
  const [isSyncing, setIsSyncing] = useState<boolean>(false);
  const { mutateAsync: mutateSyncQuotesAsync } = syncQuotes();

  const syncQuotesStatus = getSyncQuotesStatus(contactId, quoteId, {
    mutationOptions: {
      successAlertMessage: isSyncing
        ? "Quotes have synced successfully."
        : undefined,
      errorAlertMessage: isSyncing ? "Quote sync failed" : undefined,
    },
  });

  useEffect(() => {
    if (syncQuotesStatus.isSuccess) {
      if (!isSyncing && syncQuotesStatus.data === "SYNCING") {
        // Sync was triggered
        setIsSyncing(true);
      } else if (isSyncing && syncQuotesStatus.data !== "SYNCING") {
        // Sync was finished
        setIsSyncing(false);
      }
    }
  }, [syncQuotesStatus]);

  const onQuotesSync = async () => {
    mutateSyncQuotesAsync({ contactId: contactId });
  };

  return (
    <>
      {syncQuotesStatus.isSuccess && (
        <CatchELoadingButton isLoading={isSyncing} onClick={onQuotesSync} />
      )}
    </>
  );
};

export default SyncQuoteButton;
