import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { forwardRef, ReactNode, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Noop,
  Path,
  PathValue,
} from "react-hook-form";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import { getNestedProperty } from "../../helpers/object";
import {
  ValidationOptions,
  validationOptionsToControlRules,
} from "../../types/Validations";

interface APTextFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  validations: ValidationOptions;
  errors: FieldErrors<T>;
  defaultValue: PathValue<T, Path<T>> | undefined;
  helperText?: string;
  startAdornment?: string | ReactNode;
  endAdornment?: string | ReactNode;
  placeholder?: string;
  showRequired?: boolean;
  highlightOnEmpty?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  formatNumber?: boolean;
}

interface CustomNumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = forwardRef<
  NumericFormatProps,
  CustomNumberFormatProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

const APTextField = <T extends FieldValues>({
  name,
  label,
  control,
  validations,
  errors,
  defaultValue,
  helperText,
  startAdornment,
  endAdornment,
  placeholder,
  showRequired,
  highlightOnEmpty,
  readOnly,
  disabled,
  formatNumber,
}: APTextFieldProps<T>) => {
  const [focused, setFocused] = useState(false);
  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (onBlur: Noop) => {
    setFocused(false);
    onBlur();
  };

  const error = getNestedProperty(name, errors);

  return (
    <Controller<T>
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={validationOptionsToControlRules(label, validations)}
      render={({ field }) => (
        <TextField
          {...field}
          required={validations.required}
          onFocus={handleFocus}
          onBlur={() => handleBlur(field.onBlur)}
          label={showRequired ? `${label} *` : label}
          fullWidth={true}
          error={!!error || (highlightOnEmpty && !field.value)}
          placeholder={placeholder}
          helperText={`${error?.message || helperText || ""}`}
          InputProps={{
            inputComponent: formatNumber
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (NumericFormatCustom as any)
              : undefined,
            startAdornment: startAdornment && (focused || field.value) && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
            readOnly: readOnly,
            sx: { backgroundColor: "white" },
          }}
          disabled={disabled}
        />
      )}
    />
  );
};

export default APTextField;
