import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useRef } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
} from "react-hook-form";

import { getNestedProperty } from "../../helpers/object";
import {
  ValidationOptions,
  validationOptionsToControlRules,
} from "../../types/Validations";

export type AutoCompleteOption = {
  label: string;
  id: string;
};

interface APAutoCompleteProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  options: AutoCompleteOption[];
  validations: ValidationOptions;
  errors: FieldErrors<T>;
  defaultValue: PathValue<T, Path<T>> | undefined;
  showRequired?: boolean;
  highlightOnEmpty?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hiddenOptions?: string[];
  multiple?: boolean;
  setValue?: UseFormSetValue<T>;
}

const APAutoComplete = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  validations,
  errors,
  defaultValue,
  showRequired,
  highlightOnEmpty,
  readOnly,
  disabled,
  hiddenOptions,
  multiple,
  setValue,
}: APAutoCompleteProps<T>) => {
  const error = getNestedProperty(name, errors);
  const selectMenuRef = useRef<HTMLDivElement>(null);

  const onFocus = () => {
    const menuElement = selectMenuRef?.current?.children[2]?.children[0]
      ?.firstChild as HTMLElement | null;

    if (menuElement) {
      menuElement.focus();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validationOptionsToControlRules(label, validations)}
      defaultValue={defaultValue}
      render={({ field }) => {
        if (options.length > 15 && !multiple) {
          return (
            <Autocomplete
              disablePortal
              freeSolo={false}
              multiple={false}
              id={name}
              getOptionLabel={(option) => option.label || ""}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              options={options.filter(
                (option) =>
                  !hiddenOptions || hiddenOptions.indexOf(option.id) === -1
              )}
              readOnly={readOnly}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={validations.required}
                  sx={{ backgroundColor: "white" }}
                  label={showRequired ? `${label} *` : label}
                  error={!!error || (highlightOnEmpty && !field.value)}
                  helperText={`${error?.message || ""}`}
                />
              )}
              value={field.value}
              onBlur={field.onBlur}
              onChange={(event, value) => {
                field.onChange(value);
              }}
              disabled={disabled}
            />
          );
        } else {
          return (
            <FormControl
              id={name}
              fullWidth
              error={!!error || (highlightOnEmpty && !field.value)}
              disabled={disabled}
            >
              <InputLabel>
                {showRequired || validations.required ? `${label} *` : label}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  multiple
                    ? (field.value || []).map(
                        (item: AutoCompleteOption) => item.id
                      )
                    : field.value?.id || ""
                }
                renderValue={
                  multiple
                    ? (selected) => {
                        return (
                          <Box>
                            <Stack gap={1} direction="row" flexWrap="wrap">
                              {selected.map((id: string) => {
                                const selectedItem = options.find(
                                  (option) => option.id === id
                                );
                                return (
                                  <Chip
                                    key={id}
                                    label={selectedItem?.label}
                                    onDelete={() => {
                                      const newValue = field.value.filter(
                                        (item: AutoCompleteOption) =>
                                          item.id !== id
                                      );
                                      setValue &&
                                        setValue(field.name, newValue);
                                    }}
                                    deleteIcon={
                                      <CancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                  />
                                );
                              })}
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                columnGap: 1,
                                mr: 1,
                                mt: field.value.length > 0 ? 0.5 : -0.75,
                                mb: -0.75,
                                justifyContent: "end",
                              }}
                            >
                              <Button
                                size="small"
                                color="primary"
                                variant="text"
                                disableElevation={true}
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                                onClick={() => {
                                  field.onChange([]);
                                }}
                                disabled={field.value.length === 0}
                              >
                                Clear
                              </Button>
                              <Button
                                size="small"
                                color="primary"
                                variant="text"
                                disableElevation={true}
                                onMouseDown={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                                onClick={() => field.onChange([...options])}
                                disabled={field.value.length === options.length}
                              >
                                Select All
                              </Button>
                            </Box>
                          </Box>
                        );
                      }
                    : undefined
                }
                label={
                  showRequired || validations.required ? `${label} *` : label
                }
                readOnly={readOnly}
                required={validations.required}
                onBlur={field.onBlur}
                sx={{ backgroundColor: "white" }}
                onChange={(event) => {
                  const value = event.target.value;

                  if (multiple) {
                    // If multi select, check if item is being removed
                    const itemRemoved = value.length < field.value.length;

                    if (itemRemoved) {
                      const newValue = field.value.filter(
                        (option: AutoCompleteOption) =>
                          value.includes(option.id)
                      );

                      field.onChange(newValue);
                    } else {
                      // Item is being added
                      const newId = value[value.length - 1];

                      if (newId) {
                        const option = options.find(
                          (option) => option.id === newId
                        );
                        const newValue = {
                          id: newId,
                          label: option?.label || "",
                        };

                        field.onChange([...field.value, newValue]);
                      }
                    }
                  } else {
                    // If single selection
                    const option = options.find(
                      (option) => option.id === value
                    );
                    const newValue = { id: value, label: option?.label || "" };

                    field.onChange(newValue);
                  }
                }}
                MenuProps={{
                  disableAutoFocus: true,
                  MenuListProps: {
                    autoFocusItem: false,
                  },
                  ref: selectMenuRef,
                  TransitionProps: {
                    onEntered: onFocus,
                  },
                }}
                multiple={multiple}
                displayEmpty
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{
                      display:
                        hiddenOptions && hiddenOptions.indexOf(option.id) !== -1
                          ? "none"
                          : "flex",
                      alignItems: "center",
                    }}
                  >
                    {multiple && (
                      <Box sx={{ display: "flex", width: "1rem", mr: 1 }}>
                        {field.value.some(
                          (item: AutoCompleteOption) => item.id === option.id
                        ) && (
                          <CheckIcon
                            sx={{
                              width: "inherit",
                              height: "inherit",
                              color: "primary.main",
                            }}
                          />
                        )}
                      </Box>
                    )}
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{`${error?.message || ""}`}</FormHelperText>
            </FormControl>
          );
        }
      }}
    />
  );
};

export default APAutoComplete;
