import { useQuery } from "react-query";

import { AssetAndLiabilityType } from "../../types/Dossier";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getAssetsAndLiabilitiesTypes = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["asset-and-liability-types"],
    queryFn: (): Promise<AssetAndLiabilityType[]> => {
      return fetchWithAuth<AssetAndLiabilityType[]>(
        "getAssetsAndLiabilitiesTypes"
      );
    },
    enabled: isQueryEnabled(options),
  });
};
