import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactNode } from "react";

import EmptyList from "./EmptyList";

const PaginationContainer = ({
  children,
  currentPage,
  pageSize,
  itemCount,
  onPrevPage,
  onNextPage,
  emptyMessage,
}: {
  children: ReactNode;
  currentPage: number;
  pageSize: number;
  itemCount: number;
  onPrevPage: () => void;
  onNextPage: () => void;
  emptyMessage?: string;
}) => {
  const numberOfPages = pageSize ? Math.ceil(itemCount / pageSize) : 1;

  return (
    <Box>
      <Box>
        {itemCount > 0 && children}
        {itemCount === 0 && <EmptyList message={emptyMessage} />}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {numberOfPages > 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              mx: 4,
              mt: 1,
            }}
          >
            <Box>
              <IconButton
                color="inherit"
                size="small"
                sx={{ visibility: "visible" }}
                disabled={currentPage === 1}
                onClick={onPrevPage}
              >
                <ArrowLeftIcon />
              </IconButton>
            </Box>
            <Box sx={{ mx: 1 }}>
              <Typography
                variant="bodyMedium"
                onClick={onNextPage}
                sx={{ cursor: "pointer" }}
              >
                Page {currentPage} of {numberOfPages}
              </Typography>
            </Box>
            <Box>
              <IconButton
                color="inherit"
                size="small"
                sx={{ visibility: "visible" }}
                disabled={currentPage === numberOfPages}
                onClick={onNextPage}
              >
                <ArrowRightIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PaginationContainer;
