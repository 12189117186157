export const DEFAULT_ERROR_MESSAGE = "Something went wrong, please try again.";

export enum ErrorCode {
  UnknownError = "UnknownError",
  InvalidReferralCode = "InvalidReferralCode",
}

export const ErrorCodeMap: Record<ErrorCode, string> = {
  UnknownError: DEFAULT_ERROR_MESSAGE,
  InvalidReferralCode: "Referral code is not valid.",
};
