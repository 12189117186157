import { useQuery } from "react-query";

import { VehicleModel } from "../../types/Vehicles";
import { useApi } from "../ApiProvider";
import {
  APQueryOptions,
  LONG_STALE_TIME,
  useAPQuery,
} from "../APQueryProvider";

export const getVehicleModels = (
  makeId: string | null,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["vehicle-models", makeId],
    queryFn: (): Promise<VehicleModel[]> => {
      if (makeId) {
        return fetchWithAuth<VehicleModel[]>("getVehicleModels", undefined, {
          queryParams: { makeId },
        });
      } else {
        return Promise.resolve([]);
      }
    },
    enabled: isQueryEnabled(options),
    staleTime: LONG_STALE_TIME,
  });
};
