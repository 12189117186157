import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getFile } from "../../api/file/getFile";
import { toDollars } from "../../helpers/currencies";
import {
  calculateWeeklyVehicleOptionCost,
  VehicleOption,
} from "../../types/Vehicles";

const VehicleOptionDetails = ({
  option,
  savingsPercentage,
  term,
  handleOptionDownload,
}: {
  option: VehicleOption;
  savingsPercentage: number;
  term: number;
  handleOptionDownload: (url: string, name: string) => void;
}) => {
  const downloadUrl = option.productInfoDownloadURL;
  const costPerWeek = calculateWeeklyVehicleOptionCost(
    option.price,
    savingsPercentage,
    term
  );

  const imageSrc = getFile(option.imageUrl || "", true, {
    disabled: !option.imageUrl,
  });

  return (
    <Stack spacing={2} sx={{ minWidth: "320px", maxWidth: "400px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="headlineLarge">{option.name}</Typography>
        {!!downloadUrl && (
          <Box>
            <IconButton
              size="large"
              aria-label="download-quote"
              color="onSurface"
              onClick={() =>
                handleOptionDownload(
                  downloadUrl,
                  option.productInfoDownloadName || `${option.name}.pdf`
                )
              }
            >
              <DownloadIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box>
        <Typography variant="labelLarge">{option.description}</Typography>
      </Box>
      {imageSrc.data && (
        <Box sx={{ maxWidth: "inherit" }}>
          <Box
            component="img"
            src={imageSrc.data}
            sx={{ maxWidth: "inherit" }}
          ></Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="labelLarge">
          {toDollars(option.price, 0)} Inc GST
        </Typography>
        <Typography variant="labelLarge">
          {toDollars(costPerWeek, 0)}/Week^
        </Typography>
      </Box>
    </Stack>
  );
};

export default VehicleOptionDetails;
