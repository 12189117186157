import { useQuery } from "react-query";

import { Survey } from "../../types/Survey";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getSurvey = (surveyId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["surveys", surveyId],
    queryFn: (): Promise<Survey> => {
      return fetchWithAuth<Survey>("getSurvey", surveyId);
    },
    enabled: isQueryEnabled(options),
  });
};
