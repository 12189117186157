import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Alert from "./Alert";

// refresh
const ErrorBoundaryFallback = ({ error }: { error: Error }) => {
  const handleRefresh = () => {
    location.reload();
  };
  const handleHome = () => {
    location.replace("/");
  };

  return (
    <Container maxWidth={"md"} sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <Alert
          severity="error"
          title="Something went wrong."
          message={`<div>${error.message}</div>`}
          asHTML
          detailsExpandable
        />
        <Box
          sx={{
            pt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              disableElevation={true}
              onClick={() => handleRefresh()}
            >
              Back
            </Button>
          </Box>
          <Box>
            <Button
              size="large"
              color="primary"
              variant="contained"
              disableElevation={true}
              onClick={() => handleHome()}
            >
              Home
            </Button>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};

export default ErrorBoundaryFallback;
