import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const setTemporaryPassword = (
  contactId?: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();

  return useMutation({
    mutationFn: (): Promise<string> => {
      return fetchWithAuth<string>("setTemporaryPassword", contactId, {
        requestOptions: { method: "post" },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () =>
      defaultOnMutateSettled([
        ["contact", contactId],
        ["keycloakUserInfo", contactId],
      ]),
  });
};
