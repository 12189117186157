import { SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";

const CatchEButton = ({ url, sx }: { url: string; sx?: SxProps<Theme> }) => {
  return (
    <Link to={url} target="_blank">
      <IconButton
        size="large"
        aria-label="catche-quote"
        color="onSurface"
        sx={sx}
      >
        <Box
          component="img"
          src="/catchEIcon.png"
          sx={{ width: "24px", height: "24px" }}
        ></Box>
      </IconButton>
    </Link>
  );
};

export default CatchEButton;
