import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import APAutoComplete, {
  AutoCompleteOption,
} from "../../components/form/APAutoComplete";
import APForm from "../../components/form/APForm";
import APTextField from "../../components/form/APTextField";
import { buildAutoCompleteOptions } from "../../helpers/buildOptions";
import { Contact } from "../../types/Contact";
import { ContactRole, PayrollCycle } from "../../types/User";

export type DriverInputs = {
  employer: AutoCompleteOption | null;
  payrollCycle: AutoCompleteOption | null;
  employeeId: string;
};

const DriverApprovalCard = ({
  dossierId,
  dossierIdentity,
  driverContact,
  vehicleYear,
  vehicleMake,
  vehicleModel,
  roles,
  employerPayrollCycles,
  onApprove,
  onReject,
  onDownloadPaPdf,
}: {
  id: number;
  dossierId: string;
  dossierIdentity: string;
  driverContact: Contact | null;
  vehicleYear: string;
  vehicleMake: string;
  vehicleModel: string;
  roles: ContactRole[];
  employerPayrollCycles: PayrollCycle[];
  onApprove: (inputs: DriverInputs, dossierId: string) => void;
  onReject: (dossierId: string) => void;
  onDownloadPaPdf: (dossierId: string, dossierIdentity: string) => void;
}) => {
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<DriverInputs>({
    mode: "onTouched",
  });
  const watchEmployer = watch("employer");
  const previousEmployer = useRef(watchEmployer);
  const employeeName = driverContact?.name || "";

  useEffect(() => {
    if (driverContact) {
      setValue(
        "employer",
        driverContact.parentAccountId
          ? {
              id: driverContact.parentAccountId,
              label: driverContact.parentAccountEmployerName || "",
            }
          : null
      );
      setValue(
        "payrollCycle",
        driverContact.employerPayrollCycleId
          ? {
              id: driverContact.employerPayrollCycleId,
              label: driverContact.employerPayrollCycleName || "",
            }
          : null
      );
      setValue("employeeId", driverContact.employeeid || "");

      previousEmployer.current = watchEmployer;
    }
  }, []);

  useEffect(() => {
    // If there is no employer selected or the employer has been changed then clear the payroll cycle field
    if (
      !watchEmployer ||
      (previousEmployer.current &&
        watchEmployer.id !== previousEmployer.current.id)
    ) {
      setValue("payrollCycle", null);
    }
    previousEmployer.current = watchEmployer;
  }, [watchEmployer]);

  const _onApprove = (inputs: DriverInputs) => {
    onApprove(inputs, dossierId);
  };

  const payrollCycles = watchEmployer
    ? employerPayrollCycles.filter(
        (cycle) => cycle.accountId === watchEmployer.id
      )
    : [];

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "surface.main",
        rowGap: 2,
        p: 2,
      }}
      variant="outlined"
    >
      <Box>
        <Typography variant="titleMedium">
          {dossierIdentity} {employeeName}
        </Typography>
        <Typography variant="bodyMedium">
          {vehicleYear} {vehicleMake} {vehicleModel}
        </Typography>

        <Stack sx={{ pt: 2 }} spacing={2}>
          <APForm
            onSubmit={handleSubmit(_onApprove)}
            submitText={"Approve*"}
            isLoading={false}
            leftButton={
              <Button
                size="large"
                color="primary"
                variant="outlined"
                disableElevation={true}
                onClick={() => onDownloadPaPdf(dossierId, dossierIdentity)}
              >
                Auto-UX PA
              </Button>
            }
            additionalButton={
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={() => onReject(dossierId)}
              >
                Reject
              </Button>
            }
            isError={Object.keys(errors).length > 0}
          >
            <APAutoComplete
              name={"employer"}
              label="Employer"
              options={buildAutoCompleteOptions(
                roles,
                "accountName",
                "accountId"
              )}
              control={control}
              validations={{}}
              errors={errors}
              defaultValue={null}
              showRequired={true}
            />

            <APAutoComplete
              name={"payrollCycle"}
              label="Employer Payroll Cycle"
              options={buildAutoCompleteOptions(payrollCycles, "name", "id")}
              control={control}
              validations={{ required: true }}
              errors={errors}
              defaultValue={null}
            />

            <APTextField
              name={"employeeId"}
              label="Employee ID/Cost Centre"
              control={control}
              defaultValue=""
              validations={{}}
              errors={errors}
              helperText="Optional"
            />
          </APForm>
        </Stack>
      </Box>
    </Card>
  );
};

export default DriverApprovalCard;
