import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../api/AuthProvider";
import { User } from "../types/User";

const ProtectedRoute = ({
  me,
  children,
}: {
  me: User;
  children: JSX.Element;
}) => {
  const { getScopedPageUrl } = useAuth();
  const location = useLocation();

  if (!me || !me.initialSetupCompleted) {
    return (
      <Navigate to={`${getScopedPageUrl("initialUser")}${location.search}`} />
    );
  }

  return children;
};

export default ProtectedRoute;
