import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getDossier } from "../../api/dossier/getDossier";
import { setPrimaryQuote } from "../../api/dossier/setPrimaryQuote";
import { getFaqAppLocations } from "../../api/faq/getFaqAppLocations";
import { getFaqs } from "../../api/faq/getFaqs";
import { getQuote } from "../../api/quote/getQuote";
import { PatchQuotePayload, updateQuote } from "../../api/quote/updateQuote";
import PageContainer from "../../components/PageContainer";
import { buildQuoteFilename, downloadFile } from "../../helpers/download";
import { QuotePayPeriod } from "../../types/Quote";
import CalculatedQuote from "../components/CalculatedQuote";

export type ViewQuoteSubmitAction =
  | "edit"
  | "new"
  | "next"
  | "download"
  | "user";
const ViewQuote = () => {
  const { quoteId } = useParams();
  const [carouselAppLocationId, setCarouselAppLocationId] = useState(
    null as null | string
  );
  const navigate = useNavigate();
  const { fetchWithAuth } = useApi();
  const { getScopedPageUrl, getScopedPageConfig } = useAuth();

  if (!quoteId) {
    // TODO: improve client side error messaging
    throw new Error("quote id param is misisng");
  }

  const { mutateAsync, isLoading: isLoadingUpdate } = updateQuote(quoteId);

  const quote = getQuote(quoteId);

  const dossierId = quote.data?.dossierId;
  const dossier = getDossier(dossierId || "", { disabled: !dossierId });

  const pageId = "quote-view";
  const faqAppLocations = getFaqAppLocations(pageId);
  const carouselFaqs = getFaqs(carouselAppLocationId || "", {
    disabled: !carouselAppLocationId,
  });

  const invalidateList = [["quote", quoteId]];

  if (quote.data) {
    invalidateList.push(["quotes", quote.data.dossierId]);
  }

  const {
    mutateAsync: mutatePrimaryToggleAsync,
    isLoading: isLoadingPrimaryToggle,
  } = setPrimaryQuote(dossierId || "", {
    mutationOptions: {
      successAlertMessage: "Your primary quote has been updated",
    },
  });

  useEffect(() => {
    if (faqAppLocations.isSuccess) {
      const carouselApplocation = faqAppLocations.data.find(
        (location) =>
          location.appLocationIdentifier === "QuoteView-BelowLifeofLeaseSavings"
      );

      if (carouselApplocation) {
        setCarouselAppLocationId(carouselApplocation.id);
      }
    }
  }, [faqAppLocations.isSuccess]);

  if (quote.isError) {
    throw quote.error;
  }

  if (dossier.isError) {
    throw dossier.error;
  }

  if (faqAppLocations.isError) {
    throw faqAppLocations.error;
  }

  const download = async () => {
    if (quote.isSuccess && dossier.isSuccess) {
      const filename = buildQuoteFilename(
        dossier.data.identity,
        quote.data.vehicle.make,
        quote.data.vehicle.model,
        `${quote.data.term}`
      );

      const pdf = await fetchWithAuth<Blob>("getQuotePDF", quoteId, {
        responseOptions: { responseType: "blob" },
      });

      downloadFile(pdf, filename);
    }
  };

  const onSubmit = async (
    name: string,
    payPeriod: QuotePayPeriod,
    submitAction: ViewQuoteSubmitAction
  ) => {
    if (quote.isSuccess && dossier.isSuccess) {
      const updatePayload: PatchQuotePayload = {};

      if (name !== quote.data.name) {
        updatePayload.name = name;
      }

      if (payPeriod !== quote.data.payPeriod) {
        updatePayload.payPeriod = payPeriod;
      }

      if (Object.keys(updatePayload).length > 0) {
        updatePayload.dossierId = dossierId;
        await mutateAsync(updatePayload);
      }

      switch (submitAction) {
        case "next":
          navigate(getScopedPageUrl("viewDossier", dossier.data.id));
          break;
        case "new":
          navigate(getScopedPageUrl("cloneQuote", quoteId));
          break;
        case "edit":
          navigate(getScopedPageUrl("editQuote", quoteId));
          break;
        case "download":
          download();
          break;
        case "user":
          navigate(
            getScopedPageUrl("viewKeycloakUser", dossier.data.crmContactId)
          );
          break;
      }
    }
  };

  const onSetPrimary = async (quoteId: string) => {
    if (dossier.isSuccess) {
      mutatePrimaryToggleAsync({ quoteId });
    }
  };

  const handleCarouselFaqClick = (faqId: string) => {
    const faq = carouselFaqs.isSuccess
      ? carouselFaqs.data.find((faq) => faq.id === faqId)
      : undefined;

    fetchWithAuth<void>("createFaqView", faqId, {
      requestOptions: {
        method: "post",
        body: JSON.stringify({
          appLocationId: carouselAppLocationId || null,
          faqAppLocationId: faq?.faqApplocationId || null,
        }),
      },
    });
  };

  const isLoading =
    quote.isFetching || dossier.isFetching || faqAppLocations.isFetching;
  const isSuccess =
    quote.isSuccess && dossier.isSuccess && faqAppLocations.isSuccess;

  // Can create a quote if there is less then max quotes and the can create flag is true
  const canCreateQuote =
    dossier.isSuccess &&
    (dossier.data.quoteCount || 0) <
      getScopedPageConfig<number>("dossierMaxQuoteCount") &&
    (dossier.data.opportunity?.canCreateQuote || false);

  return (
    <PageContainer loading={isLoading}>
      {isSuccess && (
        <CalculatedQuote
          id={quote.data.id}
          contactId={dossier.data.crmContactId}
          name={quote.data.name || ""}
          isLocked={quote.data.isLocked}
          isPrimary={dossier.data.opportunity?.primaryQuoteId === quote.data.id}
          vehicleMake={quote.data.vehicle.make}
          vehicleModel={quote.data.vehicle.model}
          vehicleYear={quote.data.vehicle.year}
          vehicleVariantDescription={quote.data.vehicle.variantLongName}
          vehicleImages={quote.data.vehicle.images.map((image) => image.href)}
          payPeriod={quote.data.payPeriod}
          valuesPerPeriod={quote.data.valuesPerPeriod}
          runningCostsPA={quote.data.runningCostsPA}
          annualSalary={quote.data.annualSalary}
          annualKm={quote.data.annualKm}
          purchaseType={quote.data.purchaseType}
          vehicleOnroadCosts={quote.data.vehicleOnroadCosts}
          vehicleResidualGross={quote.data.vehicleResidualGross}
          term={quote.data.term}
          businessUsage={quote.data.businessUsage}
          savings={quote.data.savings}
          savingsPerYear={quote.data.savingsPerYear}
          lifeOfLeaseSavings={quote.data.lifeOfLeaseSavings}
          catchEQuoteUrl={quote.data.catchEQuoteUrl}
          canCreateQuote={canCreateQuote}
          isSubmitting={isLoadingUpdate}
          handleSubmit={onSubmit}
          handleSetPrimary={onSetPrimary}
          disablePrimaryToggle={isLoadingPrimaryToggle}
          faqAppLocations={faqAppLocations.data}
          carouselAppLocationId={carouselAppLocationId}
          carouselFaqs={
            carouselFaqs && carouselFaqs.isSuccess ? carouselFaqs.data : []
          }
          handleCarouselFaqClick={handleCarouselFaqClick}
        />
      )}
    </PageContainer>
  );
};

export default ViewQuote;
