import { PayFrequency } from "../../types/Dossier";

const PayFrequencyToPeriodsPerYearMap: Record<PayFrequency, number> = {
  monthly: 12,
  "bi-monthly": 24,
  fortnightly: 26,
  weekly: 52,
};

export const PayFrequencyToPeriodsPerYear = (
  payFrequency: PayFrequency
): number => {
  return PayFrequencyToPeriodsPerYearMap[payFrequency];
};
