export const fileUploadTypePattern =
  /(application\/msword)|(application\/pdf)|(application\/vnd\.ms-excel)|(application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet)|(application\/vnd\.openxmlformats-officedocument\.presentationml\.presentation)|(application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document)|(image\/bmp)|(image\/gif)|(image\/jpeg)|(image\/png)|(image\/tiff)|(text\/csv)/;

export const appendRedbookImageParams = (
  imageUrl: string,
  width?: number,
  height?: number
) => {
  return `${imageUrl}?pxc_method=fit&pxc_bgtype=self&pxc_size=${
    width || 1795
  }%2c${height || 1195}`;
};
