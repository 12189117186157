import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

const MenuCard = ({
  title,
  primaryButton,
  secondaryButton,
}: {
  title?: string;
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "surface.main",
        rowGap: 2,
        p: 2,
      }}
      variant="outlined"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="headlineMedium">{title}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          {primaryButton && <Box>{primaryButton}</Box>}
          {secondaryButton && <Box>{secondaryButton}</Box>}
        </Box>
      </Box>
    </Card>
  );
};

export default MenuCard;
