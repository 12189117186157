import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../api/AuthProvider";
import { getParamFromUrl, replaceModalParam } from "../helpers/routes";

const ShowMFAPrompt = ({ mfaEnabled }: { mfaEnabled: boolean }) => {
  const { getScopedPageUrl } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const modalParam = getParamFromUrl(window.location.href, "modal");

  useEffect(() => {
    if (mfaEnabled && modalParam !== getScopedPageUrl("modalEnableMFA")) {
      const newSearchParams = replaceModalParam(
        getScopedPageUrl("modalEnableMFA"),
        searchParams
      );
      const navigateTo = `${location.pathname}?${newSearchParams.toString()}`;
      navigate(navigateTo);
    }
  }, [mfaEnabled, window.location.href]);

  return <></>;
};

export default ShowMFAPrompt;
