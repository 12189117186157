import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type UpdateSurveyResponsePayload = {
  [key: string]: string | number | boolean;
};

export const updateSurveyResponse = (
  surveyId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { defaultOnMutateError, defaultOnMutateSuccess } = useAPQuery();

  return useMutation({
    mutationFn: (payload: UpdateSurveyResponsePayload): Promise<boolean> => {
      return fetchWithAuth<boolean>("updateSurveyResponse", surveyId, {
        requestOptions: { method: "PATCH", body: JSON.stringify(payload) },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
  });
};
