import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const formatDate = (date: string, format?: string): string => {
  return dayjs
    .utc(date)
    .local()
    .format(format || "D MMM YYYY");
};

export const now = () => {
  return dayjs.utc();
};
