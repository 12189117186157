import "survey-core/defaultV2.min.css";
import "../surveyjs.css";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Model, Question, QuestionRatingModel, Serializer } from "survey-core";
import { Survey, SurveyModel } from "survey-react-ui";

import { UpdateSurveyResponsePayload } from "../../api/survey/updateSurveyResponse";
import theme from "../../theme";
import { registerBooleanThumbs } from "./CustomThumbBoolean";

interface APSurveyProps {
  surveyId: string;
  schema: object;
  navigateToHome: () => void;
  onComplete: (payload: UpdateSurveyResponsePayload) => void;
}

const APSurvey = ({
  surveyId,
  schema,
  navigateToHome,
  onComplete,
}: APSurveyProps) => {
  const [isSurveyComplete, setIsSurveyComplete] = useState(false);
  const storageKey = `my-survey-${surveyId}`;

  const isRatingType = (question: Question, rateType: string) => {
    return (question as QuestionRatingModel).rateType === rateType;
  };

  const updateRatingLabel = (questionName: string, displayValue: string) => {
    const labelId = `rating-label-${questionName}`;
    const labelElement = document.getElementById(labelId);

    if (labelElement) {
      labelElement.innerText = displayValue;
    }
  };

  const saveSurveyData = useCallback((survey: SurveyModel) => {
    const data = survey.data;
    window.localStorage.setItem(storageKey, JSON.stringify(data));
  }, []);

  const restoreSurveyData = useCallback((survey: SurveyModel) => {
    const prevData = window.localStorage.getItem(storageKey) || null;

    if (prevData) {
      const data = JSON.parse(prevData);
      survey.data = data;
    }
  }, []);

  const clearCachedSurveyData = useCallback(() => {
    window.localStorage.removeItem(storageKey);
  }, []);

  const handleSurveyComplete = useCallback((survey: SurveyModel) => {
    // Check for any default on empty properties and update the questions to the default value
    survey.getAllQuestions().forEach((question) => {
      if (
        question.defaultWhenEmpty &&
        (question.value === null ||
          question.value === undefined ||
          question.value === "" ||
          (Array.isArray(question.value) && question.value.length === 0))
      ) {
        survey.setValue(question.name, question.defaultWhenEmpty);
      }
    });

    onComplete(survey.data);
    clearCachedSurveyData();
    setIsSurveyComplete(true);
  }, []);

  // Register custom question types
  registerBooleanThumbs();

  // Register a custom property called "defaultWhenEmpty"
  Serializer.addProperty("question", {
    name: "defaultWhenEmpty:boolean",
    default: undefined,
  });

  // Initialise survey
  const surveyModel = useMemo(() => {
    const surveySchema = {
      showQuestionNumbers: "off",
      autoGrowComment: true,
      allowResizeComment: false,
      completeText: "Done",
      widthMode: "responsive",
      ...schema,
    };

    return new Model(surveySchema);
  }, [schema]);

  surveyModel.applyTheme({
    themeName: "plain",
    colorPalette: "light",
    isPanelless: true,
    backgroundImage: "",
    backgroundOpacity: 1,
    backgroundImageAttachment: "scroll",
    backgroundImageFit: "cover",
    cssVariables: {
      "--sjs-corner-radius": "4px",
      "--sjs-base-unit": "8px",
      "--sjs-shadow-small": "0px 0px 0px 1px rgba(0, 0, 0, 0.15)",
      "--sjs-shadow-inner": "0px 0px 0px 1px rgba(0, 0, 0, 0.15)",
      "--sjs-border-default": "rgba(0, 0, 0, 0.15)",
      "--sjs-border-light": "rgba(0, 0, 0, 0.15)",
      "--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
      "--sjs-general-backcolor-dark": "rgba(248, 248, 248, 1)",
      "--sjs-general-backcolor-dim-light": "rgba(255, 255, 255, 1)",
      "--sjs-general-backcolor-dim-dark": "rgba(243, 243, 243, 1)",
      "--sjs-general-forecolor": theme.palette.onSurface.main,
      "--sjs-general-forecolor-light": theme.palette.onSurface.main,
      "--sjs-general-dim-forecolor": theme.palette.onSurface.main,
      "--sjs-general-dim-forecolor-light": theme.palette.onSurface.main,
      "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
      "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
      "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
      "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
      "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
      "--sjs-shadow-medium": "0px 0px 0px 1px rgba(0, 0, 0, 0.1)",
      "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.05)",
      "--sjs-shadow-inner-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
      "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
      "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-green": "rgba(25, 179, 148, 1)",
      "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
      "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-blue": "rgba(67, 127, 217, 1)",
      "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
      "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
      "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
      "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-article-font-xx-large-textDecoration": "none",
      "--sjs-article-font-xx-large-fontWeight":
        theme.typography.displayLarge.fontWeight?.toString() || "400",
      "--sjs-article-font-xx-large-fontSize":
        theme.typography.displayLarge.fontSize?.toString() || "3.563rem",
      "--sjs-article-font-xx-large-fontStyle": "normal",
      "--sjs-article-font-xx-large-fontStretch": "normal",
      "--sjs-article-font-xx-large-letterSpacing": "0",
      "--sjs-article-font-xx-large-lineHeight": "64px",
      "--sjs-article-font-xx-large-paragraphIndent": "0px",
      "--sjs-article-font-xx-large-textCase": "none",
      "--sjs-article-font-x-large-textDecoration": "none",
      "--sjs-article-font-x-large-fontWeight":
        theme.typography.headlineLarge.fontWeight?.toString() || "400",
      "--sjs-article-font-x-large-fontSize":
        theme.typography.headlineLarge.fontSize?.toString() || "2rem",
      "--sjs-article-font-x-large-fontStyle": "normal",
      "--sjs-article-font-x-large-fontStretch": "normal",
      "--sjs-article-font-x-large-letterSpacing": "0",
      "--sjs-article-font-x-large-lineHeight": "56px",
      "--sjs-article-font-x-large-paragraphIndent": "0px",
      "--sjs-article-font-x-large-textCase": "none",
      "--sjs-article-font-large-textDecoration": "none",
      "--sjs-article-font-large-fontWeight":
        theme.typography.headlineMedium.fontWeight?.toString() || "400",
      "--sjs-article-font-large-fontSize":
        theme.typography.headlineMedium.fontSize?.toString() || "2rem",
      "--sjs-article-font-large-fontStyle": "normal",
      "--sjs-article-font-large-fontStretch": "normal",
      "--sjs-article-font-large-letterSpacing": "0",
      "--sjs-article-font-large-lineHeight": "40px",
      "--sjs-article-font-large-paragraphIndent": "0px",
      "--sjs-article-font-large-textCase": "none",
      "--sjs-article-font-medium-textDecoration": "none",
      "--sjs-article-font-medium-fontWeight":
        theme.typography.headlineSmall.fontWeight?.toString() || "400",
      "--sjs-article-font-medium-fontSize":
        theme.typography.headlineSmall.fontSize?.toString() || "1.5rem",
      "--sjs-article-font-medium-fontStyle": "normal",
      "--sjs-article-font-medium-fontStretch": "normal",
      "--sjs-article-font-medium-letterSpacing": "0",
      "--sjs-article-font-medium-lineHeight": "32px",
      "--sjs-article-font-medium-paragraphIndent": "0px",
      "--sjs-article-font-medium-textCase": "none",
      "--sjs-article-font-default-textDecoration": "none",
      "--sjs-article-font-default-fontWeight":
        theme.typography.bodyMedium.fontWeight?.toString() || "400",
      "--sjs-article-font-default-fontStyle": "normal",
      "--sjs-article-font-default-fontStretch": "normal",
      "--sjs-article-font-defautl-fontSize":
        theme.typography.bodyMedium.fontSize?.toString() || "0.875rem",
      "--sjs-article-font-default-letterSpacing": "0",
      "--sjs-article-font-default-lineHeight": "28px",
      "--sjs-article-font-default-paragraphIndent": "0px",
      "--sjs-article-font-default-textCase": "none",
      "--sjs-font-family": "Manrope, Roboto",
      "--sjs-font-headertitle-weight":
        theme.typography.headlineLarge.fontWeight?.toString() || "400",
      "--sjs-font-headertitle-size":
        theme.typography.headlineLarge.fontSize?.toString() || "2rem",
      "--sjs-font-headertitle-color": theme.palette.onSurface.main,
      "--sjs-font-headerdescription-weight":
        theme.typography.labelLarge.fontWeight?.toString() || "500",
      "--sjs-font-headerdescription-size":
        theme.typography.labelLarge.fontSize?.toString() || "0.875rem",
      "--sjs-font-headerdescription-color": theme.palette.text.primary,
      "--sjs-font-questiontitle-weight":
        theme.typography.bodyLarge.fontWeight?.toString() || "400",
      "--sjs-font-questiontitle-size":
        theme.typography.bodyLarge.fontSize?.toString() || "1rem",
      "--sjs-font-questiontitle-color": theme.palette.text.primary,
      "--sjs-general-backcolor-dim": "rgba(255, 255, 255, 1)",
      "--sjs-primary-backcolor": "rgba(0, 171, 208, 1)",
      "--sjs-primary-backcolor-dark": "rgba(0, 159, 193, 1)",
      "--sjs-primary-backcolor-light": "rgba(0, 171, 208, 0.1)",
      "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
      "--sjs-special-red": "rgba(179, 38, 30, 1)",
      "--sjs-special-red-light": "rgba(179, 38, 30, 0.1)",
      "--sjs-header-backcolor": "transparent",
    },
    header: {
      height: 50,
      inheritWidthFrom: "survey",
      textAreaWidth: 512,
      overlapEnabled: false,
      backgroundImage: "",
      backgroundImageOpacity: 0,
      backgroundImageFit: "cover",
      logoPositionX: "right",
      logoPositionY: "top",
      titlePositionX: "left",
      titlePositionY: "top",
      descriptionPositionX: "left",
      descriptionPositionY: "top",
    },
    headerView: "advanced",
  });

  // Add custom navigation button for Home Page
  surveyModel.addNavigationItem({
    id: "sv-home-page",
    title: "Home",
    action: navigateToHome,
    css: "nav-button",
    innerCss: "sd-btn nav-input sd-home-btn",
    location: "start",
    visibleIndex: 0,
  });

  surveyModel.onComplete.add(handleSurveyComplete);

  useEffect(() => {
    // Restore survey data
    restoreSurveyData(surveyModel);

    surveyModel.onValueChanged.add((survey, options) => {
      // If the question is a star rating then display the value of the selected rating
      if (isRatingType(options.question, "stars")) {
        // Update inner text of wrapper to questions display value
        updateRatingLabel(
          options.question.name,
          options.question.getDisplayValue(true)
        );
      }

      // Save response to localstorage
      saveSurveyData(survey);
    });

    surveyModel.getAllQuestions().forEach((question) => {
      const type = question.getType();

      switch (type) {
        // If the question is a star rating then append the label element that will be used
        // to display the value of the selected rating
        case "rating": {
          if (isRatingType(question, "stars")) {
            // Create a label and append to question wrapper
            const questionName = question.name;
            const labelId = `rating-label-${questionName}`;
            const wrapperElement = question.getWrapperElement();
            const labelElement = document.createElement("div");
            labelElement.classList.add(
              "MuiTypography-root",
              "MuiTypography-titleLarge"
            );

            labelElement.style.color = theme.palette.primary.main;
            labelElement.style.textAlign = "center";
            labelElement.style.marginTop = "12px";
            labelElement.style.height = "24px";

            labelElement.id = labelId;
            wrapperElement.appendChild(labelElement);

            if (question.value) {
              updateRatingLabel(question.name, question.getDisplayValue(true));
            }
          }
          break;
        }
        default:
          break;
      }
    });
  }, []);

  return (
    <Box>
      <Survey model={surveyModel} />
      {isSurveyComplete && (
        <Box>
          <Button
            size="large"
            color="primary"
            variant="outlined"
            disableElevation={true}
            onClick={navigateToHome}
          >
            Home
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default APSurvey;
